import { useMemo, useState } from "react"
import { AxiosError, AxiosPromise } from "axios"

type useApiConfig = {
    callback: (...args: any[]) => Promise<any> | AxiosPromise
}

export type ApiErrorType = {
    status: number
    error: string
    message: string
}

export type useApiHook = {
    run: (...args: any[]) => Promise<any>
    isPending: boolean
    error?: ApiErrorType
}

const useApi = (props: useApiConfig): useApiHook => {
    const {
        callback
    } = props

    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState<ApiErrorType>()

    const run = useMemo(() => async (...args: any[]): Promise<any> => {
        try {
            setIsPending(true)

            const response = await callback(args)

            return response
        } catch (e) {
            if (e instanceof AxiosError) {
                const { response } = e

                setError({
                    status: response?.data.statusCode,
                    error: response?.data.error,
                    message: response?.data.message
                })
            }
        } finally {
            setIsPending(false)
        }
    }, [])

    return { run, isPending, error }
}

export default useApi