import { useEffect, useState } from "react"

import { Country } from "@services/country/model"
import { api } from "@services/country/api"

const useCountries = ({ initValue, deps }: { initValue: Country[], deps: any[] } = { initValue: [], deps: [] }) => {
    const [countries, setCountries] = useState<Country[]>(initValue)

    useEffect(() => {
        // LOAD COUNTRIES
        load()

        async function load() {
            const { data } = await api.find({ pagination: { perPage: 999999 }, sort: { name: 'ASC' } })

            setCountries(data)
        }

    }, deps)


    return { countries }
}

export default useCountries