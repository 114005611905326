import React, { ReactElement, FC, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import axios, { AxiosError } from 'axios'

import { API_HOST } from '@services/constants'

type FormData = {
    userId: string
}

const ActivatePage: FC = (): ReactElement => {
    const [searchParams] = useSearchParams();

    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = useForm<FormData>()

    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSucces] = useState(false)
    const navigate = useNavigate()

    const userId = searchParams.get('uid');
    const activationKey = searchParams.get('k');

    const onSubmit: SubmitHandler<FormData> = async (data) => {

        try {
            await axios.post(`${API_HOST}/auth/activate/${activationKey}`, data)

            setIsSucces(true)

            setTimeout(() => {
                navigate('/login')
            }, 3000)
        } catch (error: unknown) {

            if (error instanceof AxiosError) {
                setErrorMessage(error.response?.data?.message || error.message)
            }
        }
    }

    return <>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-10 col-md-4 mt-5">

                    {
                        userId === null || activationKey === null
                            ? <div className='text-danger'>Oooops! Mancano i parametri per l'attivazione dell'account</div>
                            : isSuccess
                                ? <h3> Account attivato con successo... </h3>
                                : <form onSubmit={handleSubmit(onSubmit)}>
                                    <p> Clicca sul pulsante qui sotto per attivare l'account </p>
                                    <input type="hidden"
                                        value={userId}
                                        {...register("userId", { required: true })} />
                                    <div>
                                        <button
                                            type="submit"
                                            className='btn btn-primary'
                                            disabled={!isValid}>
                                            Attiva
                                        </button>
                                    </div>
                                    <div className='mt-3'>
                                        {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
                                    </div>
                                </form>
                    }

                </div>
            </div>
        </div>

    </>;
};

export default ActivatePage