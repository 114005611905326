import React, { ReactElement, FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { OnChangePaginationFn } from '@hooks/usePagination'
import TablePagination from '@components/organisms/table/TablePagination'
import Table from '@components/organisms/table/Table'
import { Pagination } from '@services/api.model'
import { Exercise, ExerciseStatus } from '@services/exercise/exercise.model'

type ExerciseTableProps = {
    exercises: Exercise[]
    total: number
    onDelete: (exercise: Exercise) => void
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
}

const ExerciseTable: FC<ExerciseTableProps> = ({ exercises, total, onDelete, pagination, onChangePagination }): ReactElement => {
    const navigate = useNavigate()

    const columns = useMemo<ColumnDef<Exercise>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                cell: d => {
                    const status = d.cell.getValue() as ExerciseStatus

                    return <span className={`badge ms-2 ${status === 'publish' ? 'bg-success' : 'bg-secondary'}`}>
                        {status === 'draft' ? 'Draft' : 'Published'}
                    </span>
                }
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    <button className="btn btn-primary btn-sm" onClick={() => navigate(`/exercises/${props.row.original.id}`)}>Modifica</button>
                    <button className="btn btn-danger btn-sm ms-1" onClick={() => onDelete(props.row.original)}>Elimina</button>
                </>
            }

        ], [])

    const table = useReactTable<Exercise>({
        data: exercises,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
    })

    return <>
        <Table<Exercise> table={table} />
        <TablePagination<Exercise> table={table} onChangePagination={onChangePagination} />
    </>
}

export default ExerciseTable