import { Table } from "@tanstack/react-table"

import { OnChangePaginationFn } from "@/hooks/usePagination"

type TablePaginationProps<T> = {
    table: Table<T>,
    onChangePagination: OnChangePaginationFn
}

function TablePagination<T extends any>({ table, onChangePagination }: TablePaginationProps<T>) {
    return (
        <nav aria-label="Table navigation">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${!table.getCanPreviousPage() ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="First"
                        aria-disabled={!table.getCanPreviousPage()}
                        onClick={() => onChangePagination('first')}>
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                <li className={`page-item ${!table.getCanPreviousPage() ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="Previous"
                        aria-disabled={!table.getCanPreviousPage()}
                        onClick={() => onChangePagination('prev')}>
                        <span aria-hidden="true">&lsaquo;</span>
                    </button>
                </li>
                <li className='d-flex align-items-center px-1'>
                    <input
                        type="number"
                        value={table.getState().pagination.pageIndex + 1}
                        onChange={e => onChangePagination('goto', Number(e.target.value))}
                        className="form-control"
                    />
                </li>
                <li className={`page-item ${!table.getCanNextPage() ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="Next"
                        aria-disabled={!table.getCanNextPage()}
                        onClick={() => onChangePagination('next')}>
                        <span aria-hidden="true">&rsaquo;</span>
                    </button>
                </li>
                <li className={`page-item ${!table.getCanNextPage() ? 'disabled' : ''}`}>
                    <button className="page-link"
                        aria-label="Last"
                        aria-disabled={!table.getCanNextPage()}
                        onClick={() => onChangePagination('last')}>
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default TablePagination