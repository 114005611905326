import React, { ReactElement, FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Patient } from '@services/patient/model'
import { OnChangePaginationFn } from '@/hooks/usePagination'
import TablePagination from '@/components/organisms/table/TablePagination'
import Table from '@/components/organisms/table/Table'
import { Pagination } from '@/services/api.model'

type PatientTableProps = {
    patients: Patient[]
    total: number
    onDelete: (patient: Patient) => void
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
}

const PatientTable: FC<PatientTableProps> = ({ patients, total, onDelete, pagination, onChangePagination }): ReactElement => {
    const navigate = useNavigate()

    const columns = useMemo<ColumnDef<Patient>[]>(
        () => [
            {
                id: 'fullName',
                header: 'Nome',
                accessorFn: row => `${row.firstName} ${row.lastName}`
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorKey: 'birthdate',
                header: 'Data di nascita',
                cell: d => (d.cell.getValue() as Date)?.toLocaleDateString()
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    <button className="btn btn-primary btn-sm" onClick={() => navigate(`/patients/${props.row.original.id}`)}>Visualizza</button>
                    <button className="btn btn-danger btn-sm ms-1" onClick={() => onDelete(props.row.original)}>Elimina</button>
                </>
            }
        ], [])

    const table = useReactTable<Patient>({
        data: patients,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
    })

    return <>
        <Table<Patient> table={table} />
        <TablePagination<Patient> table={table} onChangePagination={onChangePagination} />
    </>
}

export default PatientTable