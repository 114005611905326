import { ReactNode, FC, ReactElement } from "react"
import { useFormContext } from "react-hook-form"

type FormControlWrapperProps = {
    children: ReactNode
    name: string
    label: string,
    id: string,
    labelId: string,
    descriptionId: string
}

const FormControlWrapper: FC<FormControlWrapperProps> = ({ children, name, label, id, labelId, descriptionId }): ReactElement => {
    const { formState: { errors } } = useFormContext()

    return (
        <>
            <label
                htmlFor={id}
                aria-labelledby={labelId}
                className="form-label d-block">{label}</label>
            {children}
            <div id={descriptionId}
                className="form-text">
                <div className="text-danger">
                    {/* 
                // @ts-ignore */}
                    {errors[name] && <span>{errors[name]?.message}</span>}
                </div>
            </div>
        </>
    )
}

export default FormControlWrapper