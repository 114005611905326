import './CalendarEvent.scss'

import { FC, useEffect, useMemo, useState } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { DateTime } from "luxon"
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async"

import FormControlWrapper from "@components/atoms/FormControlWrapper"
import useCommonFormControlAttributes from '@hooks/useCommonFormControlAttributes'
import { api as patientApi } from '@/services/patient/api'
import { SingleValue } from 'react-select'
import { Appointment, AppointmentId, CreateAppointment, UpdateAppointment } from '@/services/calendar/appointment/model'
import { getCurrentUserState } from '@/state'
import { DATE_TIME_FORMAT } from '@/services/constants'

type EventFormData = {
    id?: string
    patientId: string
    start: DateTime
    end: DateTime
    title?: string
    description?: string
    sendReminder?: boolean
}

type SelectedSlot = { start: DateTime, end: DateTime }

type CalendarEventProps = {
    event?: SelectedSlot | Appointment
    onSave: (appointment: CreateAppointment | UpdateAppointment) => void
    onDelete: (id: AppointmentId) => void
}

export const CalendarEvent: FC<CalendarEventProps> = ({ event, onSave, onDelete }) => {
    const methods = useForm<EventFormData>()
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { isValid },
    } = methods
    const [patientOption, setPatientOption] = useState<{ value: string, label: string }>()
    const currentUserState = getCurrentUserState()

    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const isEditMode = event && 'id' in event

    useEffect(() => {
        if (!event) {
            return
        }

        setValue('start', event.start)
        setValue('end', event.end)

        if (isEditMode) {
            setValue('id', event.id)
            setValue('patientId', event.patientId)
            setValue('title', event.title)
            setValue('description', event.description);

            // Fetch patient
            (async () => {
                const p = await patientApi.findBy(event.patientId)

                p && setPatientOption({ value: p.id, label: `${p.lastName} ${p.firstName}` })
            })()
        }

    }, [event])

    const loadPatients = useMemo(() => async (str: string) => {
        const res = await patientApi.find({
            filters: { lastName: { containsi: str } },
            pagination: { perPage: str.length > 4 ? -1 : 10 }
        })

        const options = res.data.map(p => ({ value: p.id, label: `${p.lastName} ${p.firstName}` }))

        return options
    }, [])

    const onSubmit: SubmitHandler<EventFormData> = async (data: EventFormData) => {
        let end = DateTime.fromObject(data.start.toObject())
        end = end.set({ hour: data.end.hour, minute: data.end.minute })

        const appointment: CreateAppointment | UpdateAppointment = {
            ...(data.id && { id: data.id }),
            patientId: data.patientId,
            userId: currentUserState!.user.id,
            description: data.description,
            sendReminder: data.sendReminder,
            status: 'booked',
            title: data.title,
            start: data.start,
            end,
        }

        onSave(appointment)
    }

    return <div className="calendar-event">
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3">
                    <div className="col-6">
                        <FormControlWrapper
                            label='Inizio'
                            name={getFieldName('start')}
                            {...makeCommonFormControlWrapperAttributes('start')}>
                            <Controller
                                control={control}
                                name={getFieldName('start') as 'start'}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value } }) =>
                                    <DatePicker
                                        selected={value?.toJSDate()}
                                        onChange={(value) => onChange(value && value instanceof Date && DateTime.fromJSDate(value))} // send value to hook form
                                        onBlur={onBlur}
                                        dateFormat={DATE_TIME_FORMAT}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        className='form-control'
                                        required
                                        withPortal
                                        {...makeCommonFormControlAttributes('start')}
                                    />
                                }
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col-6">
                        <FormControlWrapper
                            label='Fine'
                            name={getFieldName('end')}
                            {...makeCommonFormControlWrapperAttributes('end')}>
                            <Controller
                                control={control}
                                name={getFieldName('end') as 'end'}
                                rules={{
                                    required: true,
                                    validate: (end, { start }) => {
                                        return (end.hour >= start.hour)
                                            || (end.hour > start.hour && end.minute > start.minute)
                                    },
                                }}
                                render={({ field: { onChange, onBlur, value } }) =>
                                    <div className="input-group has-validation">
                                        <DatePicker
                                            selected={value?.toJSDate()}
                                            onChange={(value) => onChange(value && value instanceof Date && DateTime.fromJSDate(value))} // send value to hook form
                                            onBlur={onBlur}
                                            dateFormat="HH:mm"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            className="form-control"
                                            {...makeCommonFormControlAttributes('end')}
                                        />
                                    </div>
                                }
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col-12">
                        <FormControlWrapper
                            label='Paziente'
                            name={getFieldName('patientId')}
                            {...makeCommonFormControlWrapperAttributes('patientId')}>
                            <Controller
                                name={getFieldName('patientId')}
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return <AsyncSelect
                                        required
                                        cacheOptions
                                        defaultOptions
                                        onBlur={onBlur}
                                        onChange={onSingleValueSelectChangeFactory(onChange)}
                                        {...(!isEditMode && { loadOptions: loadPatients })}
                                        {...(!isEditMode && { isClearable: true })}
                                        {...(isEditMode && { value: patientOption })}
                                        {...(isEditMode && { isDisabled: true })}
                                        {...makeCommonFormControlAttributes('patientId')}
                                    />
                                }
                                }
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col-12">
                        <FormControlWrapper
                            label='Titolo'
                            name={getFieldName('title')}
                            {...makeCommonFormControlWrapperAttributes('title')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('title')}
                                {...register('title')}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col-12">
                        <FormControlWrapper
                            label='Descrizione'
                            name={getFieldName('description')}
                            {...makeCommonFormControlWrapperAttributes('description')}>
                            <textarea
                                rows={3}
                                className="form-control"
                                {...makeCommonFormControlAttributes('description')}
                                {...register('description')}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input"
                                type="checkbox"
                                value=""
                                id="sendReminder"
                                {...register("sendReminder")} />
                            <label className="form-check-label" htmlFor="sendReminder">
                                Invia reminder
                            </label>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isValid}>
                            {isEditMode ? 'Salva' : 'Crea'}
                        </button>

                        {
                            isEditMode &&
                            <button
                                type="button"
                                className="btn btn-danger ms-auto"
                                onClick={onDelete.bind(this, { id: event.id })}>
                                Elimina
                            </button>
                        }

                    </div>
                </div>
            </form>
        </FormProvider>
    </div>
}

function onSingleValueSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: string, label: string }>) => {
        onChange(option?.value)
    }
}