import React, { ReactElement, FC, useState, useEffect } from 'react'
import { useAtom } from 'jotai/react'

import WorkspaceForm from './WorkspaceForm'
import WorkspaceTable from './WorkspaceTable'
import { currentUserState } from '@/state'
import { api } from '@services/user/api'
import { Tenant } from '@/services/tenant/model'
import usePagination from '@/hooks/usePagination'

const WorkspacePage: FC = (): ReactElement => {
    const [currentUser, setCurrentUser] = useAtom(currentUserState)
    const [total, setTotal] = useState(-1)
    const { pagination, onChangePagination } = usePagination({ total, init: { page: 0, perPage: 5 } })
    const [tenants, setTenants] = useState<Tenant[]>([])

    useEffect(() => {
        setTotal(currentUser?.user.tenants.length || -1)
        setTenants(currentUser?.user.tenants.slice(pagination.page * pagination.perPage, pagination.page * pagination.perPage + pagination.perPage) || [])
    }, [pagination, currentUser])

    const onCreate = async () => {
        // Trigger tenants list refresh
        const user = await api.me()

        setCurrentUser({ user: user!, tenantId: user?.tenants[0]?.id! })
        setTotal(user?.tenants.length || -1)
    }

    const selectWorkspace = (t: Tenant) => {
        setCurrentUser(prev => ({ ...prev!, tenantId: t.id }))
    }

    const deteleWorkspace = async (t: Tenant) => {
        if (window.confirm("Vuoi eliminare veramente lo spazio di lavoro?") !== true) {
            return
        }

        if (!await api.deleteTenant(t)) {
            return
        }

        // Trigger tenants list refresh
        const user = await api.me()

        setCurrentUser({ user: user!, tenantId: user?.tenants[0]?.id! })
        setTotal(user?.tenants.length || -1)
    }

    return <>
        <div className="container-fluid">
            <div className="row border-bottom py-3">
                <div className="col-10">
                    <h3>Crea nuovo spazio di lavoro</h3>
                    <WorkspaceForm onCreate={onCreate} />
                </div>
            </div>
            <div className="row py-3">
                <div className="col-12">
                    <h3>I tuoi spazi di lavoro:</h3>
                    <WorkspaceTable
                        tenants={tenants}
                        total={total}
                        currentTenantId={currentUser?.tenantId}
                        pagination={pagination}
                        onChangePagination={onChangePagination}
                        onActivate={selectWorkspace}
                        onDelete={deteleWorkspace}
                    />
                </div>
            </div>
        </div>
    </>
};

export default WorkspacePage