import { FC, ReactElement } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import Select, { SingleValue } from 'react-select'

import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { CreateTherapySession, THERAPY_SESSION_TYPES } from "@services/clinical-episode/therapy-session/model"

type TherapySessionFormPros = {
    onSave: (ce: CreateTherapySession, cb: () => void) => void,
}

const TherapySessionForm: FC<TherapySessionFormPros> = ({ onSave }): ReactElement => {
    const methods = useForm<CreateTherapySession>()
    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { isValid }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const onSubmit: SubmitHandler<any> = async (data) => {
        const onSaveResetForm = () => reset(getValues())

        onSave(data, onSaveResetForm)
    }

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <FormControlWrapper
                        label='Data'
                        name={getFieldName('date')}
                        {...makeCommonFormControlWrapperAttributes('date')}>
                        <Controller
                            control={control}
                            name={getFieldName('date') as any}
                            defaultValue={new Date()}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <DatePicker
                                    onChange={(event: Date) => onChange(event)} // send value to hook form
                                    onBlur={onBlur} // notify when input is touched/blur
                                    selected={value}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    withPortal
                                    showYearDropdown
                                    showMonthDropdown
                                    required={true}
                                    className='form-control'
                                    {...makeCommonFormControlAttributes('date')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>
                <div className="col-12">
                    <FormControlWrapper
                        label='Tipologia'
                        name={getFieldName('type')}
                        {...makeCommonFormControlWrapperAttributes('type')}>
                        <Controller
                            name={getFieldName('type')}
                            render={({ field: { onChange, onBlur } }) =>
                                <Select
                                    options={getTypesOptions()}
                                    onChange={onTypeChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    required={true}
                                    {...makeCommonFormControlAttributes('type')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>
                <div className="col-12">
                    <FormControlWrapper
                        label='Descrizione'
                        name={getFieldName('description')}
                        {...makeCommonFormControlWrapperAttributes('description')}>
                        <textarea
                            rows={10}
                            className="form-control"
                            {...makeCommonFormControlAttributes('description')}
                            {...register('description')}
                        />
                    </FormControlWrapper>
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid}>
                        Salva
                    </button>
                </div>
            </div>
        </form>
    </FormProvider>
}

export default TherapySessionForm


function getTypesOptions() {
    return THERAPY_SESSION_TYPES.map(t => ({ value: t.id, label: t.name }))
}

const onTypeChangeFactory = (onChange: (event: any) => void) => {
    return (option: SingleValue<{ value: number, label: string }>) => {
        //TRIGGER onChange
        onChange(option && { id: option?.value, name: option?.label })
    }
}