import { FC, ReactElement } from "react"
import { NavLink, useMatches } from "react-router-dom"

type PropType = {}

const Breadcrumbs: FC<PropType> = (): ReactElement => {
    const matches = useMatches()

    if (matches.length === 1) {
        return <></>
    }

    return <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb p-2">
                {
                    matches.map((r, idx) => {
                        const isLast = idx === matches.length - 1
                        let title = undefined

                        // @ts-ignore
                        if (r.handle && r.handle.title) {
                            // @ts-ignore
                            title = typeof r.handle.title === 'function' ? r.handle.title(r.data) : r.handle.title
                        }

                        return title && <li
                            key={idx}
                            className={`breadcrumb-item${isLast ? ' active' : ''}`}
                            {...(isLast ? { "aria-current": 'page' } : {})}>
                            {
                                isLast
                                    ? title
                                    : <NavLink to={r.pathname}>{title}</NavLink>
                            }
                        </li>
                    })
                }
            </ol>
        </nav>
    </>
}

export default Breadcrumbs