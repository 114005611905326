import { jwtDecode } from "jwt-decode"

import { client as api } from "./api"
import { setJwtState } from "@/state"

async function signup({ email, password }: { email: string, password: string }) {
    return await api.post(`/auth/signup`, { email: email, password: password })
}

async function login({ email, password }: { email: string, password: string }) {
    const res = await api.post<{ token: string }>(`/auth/login`, { email, password })

    const jwt = res.data.token
    const decodedJwt = jwtDecode(jwt)

    setJwtState({
        token: jwt,
        decoded: decodedJwt
    })
}

async function resetPassword({ email }: { email: string }): Promise<void> {
    await api.post<any>(`/auth/reset-password`, { email: email })
}

async function updatePassword({ userId, key, password }: { userId: string, password: string, key: string }): Promise<void> {
    await api.post<any>(`/auth/update-password`, { userId: userId, resetPasswordKey: key, password: password })
}

export const authApi = {
    signup,
    login,
    resetPassword,
    updatePassword
}

export default authApi