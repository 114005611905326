import React, { ReactElement, FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import AuthFormWrapper from '@components/layout/AuthFormWrapper'
import RequestResetForm from './RequestResetForm'
import ChangePasswordForm from './ChangePasswordForm'

const ResetPasswordPage: FC = (): ReactElement => {
    const [searchParams] = useSearchParams();

    const userId = searchParams.get('uid');
    const resetPasswordKey = searchParams.get('k');

    return <>
        <AuthFormWrapper>
            {
                userId && resetPasswordKey
                    ? <ChangePasswordForm userId={userId} resetKey={resetPasswordKey}/>
                    : <RequestResetForm />
            }
        </AuthFormWrapper>
    </>
};

export default ResetPasswordPage