import { FC, ReactElement } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import Select, { SingleValue } from "react-select"

import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { User, UpdateMe } from "@services/user/model"

type AccountFormPros = {
    user: User
    onSave: (u: UpdateMe, cb: () => void) => void,
}

const AccountForm: FC<AccountFormPros> = ({ onSave, user }): ReactElement => {
    const methods = useForm<User>({ defaultValues: user })
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { isValid, isDirty }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const onSubmit: SubmitHandler<UpdateMe> = async (data) => {
        const onSaveResetForm = () => reset(getValues())

        onSave(data, onSaveResetForm)
    }

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <h3>{user.email}</h3>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <FormControlWrapper
                        label='Cognome'
                        name={getFieldName('lastName')}
                        {...makeCommonFormControlWrapperAttributes('lastName')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('lastName')}
                            {...register('lastName')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <FormControlWrapper
                        label='Nome'
                        name={getFieldName('firstName')}
                        {...makeCommonFormControlWrapperAttributes('firstName')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('firstName')}
                            {...register('firstName')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                    <FormControlWrapper
                        label='Lingua'
                        name={getFieldName('language')}
                        {...makeCommonFormControlWrapperAttributes('language')}>
                        <Controller
                            name={getFieldName('language')}
                            render={({ field: { onChange, onBlur } }) =>
                                <Select
                                    options={getLanguageOptions()}
                                    defaultValue={getLanguageOption(user.language)}
                                    onChange={onLanguageSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    {...makeCommonFormControlAttributes('language')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        Salva
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default AccountForm


const LanguageMapper: Record<string, string> = {
    'it': 'Italiano',
    'en': 'Inglese',
}

function getLanguageOptions() {
    return Object
        .keys(LanguageMapper)
        .map(l => ({ value: l, label: LanguageMapper[l] }))
}

function getLanguageOption(language?: string) {
    return (language !== undefined && { value: language, label: LanguageMapper[language] }) || undefined
}

function onLanguageSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: string, label: string }>) => {
        onChange(option?.value)
    }
}