import React, { ReactElement, FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { PASSWORD_PATTERN } from '@services/constants'
import api from '@services/auth.service'

type FormData = {
  password: string
}

type PropTypes = {
  userId: string,
  resetKey: string
}

const ChangePasswordForm: FC<PropTypes> = ({ userId, resetKey }): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>()

  const [errorMessage, setErrorMessage] = useState('')
  const [isSent, setIsSent] = useState(false)
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      
      await api.updatePassword({ userId: userId, key: resetKey, password: data.password })

      setIsSent(true)

      // Reindirizza
      setTimeout(() => {
        navigate('/login')
      }, 3000)

    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.message || error.message)
      }
    }
  }

  if (isSent) {
    return <>
      <h3>
        Password reimpostata con successo. <br />
        Verrai reindirizzato alla pagina di login...
      </h3>
    </>
  } else {
    return <>
      <form onSubmit={handleSubmit(onSubmit)}
        className='border rounded-1 p-4'>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">Nuova password</label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            aria-labelledby='passwordHelpBlock'
            {...register("password", { required: true, pattern: PASSWORD_PATTERN })}
          />
          <div id="passwordHelpBlock" className="form-text">
            {errors.password && <span>This field is required.</span>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className='btn btn-primary'
            disabled={!isValid}>
            Cambia
          </button>
        </div>
        <div className='mt-3'>
          {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
        </div>
      </form>
    </>
  }
};

export default ChangePasswordForm;
