import { FC, useState } from "react"
import ReactPlayer from "react-player/file"

import './VideoPlayer.scss'

import { PublicWorkoutPlanExerciseMedia } from "@/services/workout-plan/workout-plan-exercise/model"

type VideoPlayerProps = {
    media: PublicWorkoutPlanExerciseMedia
    ar?: {
        width: number
        height: number
    }
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ media, ar = { width: 3, height: 4 } }) => {
    const [playing, setPlaying] = useState(false)
    const style = { "--media-width": ar.width, "--media-height": ar.height } as React.CSSProperties
    const baseSrc = media.url.href.replace(/\.[^/.]+$/, "")

    return <div className="media media--video" {...{ style }}>
        <div className="media__content">
            <ReactPlayer
                playing={playing}
                loop={true}
                url={[
                    { src: `${baseSrc}.webm`, type: 'video/webm' },
                    { src: `${baseSrc}.mp4`, type: 'video/mp4' },
                ]}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }} />
        </div>
        <div className={`video-controls${playing ? " video-controls--playing" : ""}`}>
            <button onClick={() => setPlaying(prev => !prev)}>
                {
                    playing
                        ? <i className="bi bi-pause-fill" />
                        : <i className="bi bi-play-fill" />
                }
            </button>
        </div>
    </div >
}