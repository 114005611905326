import './MainLayout.scss'

import React, { ReactElement, FC } from 'react'
import { Outlet, useNavigation } from 'react-router-dom'
import { useAtom } from 'jotai/react'

import { currentUserState } from '@/state'
import { Tenant } from '@services/tenant/model'
import Sidebar from '@components/organisms/Sidebar'
import Breadcrumbs from '@components/molecules/breadcrumb/Breadcrumbs'
import Loader from '@components/atoms/Loader'

const MainLayout: FC = (): ReactElement => {
  const [currentUser] = useAtom(currentUserState)
  const { state } = useNavigation()

  const getCurrentWorkspaceName = () => {
    if (!currentUser) {
      return 'nessuno'
    }

    const tenant: Tenant = currentUser?.user.tenants
      .filter((t: Tenant) => t.id === currentUser.tenantId)[0]

    return tenant ? tenant.name : 'nessuno'
  }

  return (
    <div className="layout--main">
      <Sidebar />
      <div className='header border-bottom'>
        <h3 className='p-2'>Workspace: {getCurrentWorkspaceName()}</h3>
        <Breadcrumbs />
      </div>
      {
        state === 'loading'
          ? <div className='main-content p-3 d-flex flex-column justify-content-center'>
            <Loader />
          </div>
          : <div className='main-content p-3'>
            <Outlet />
          </div>
      }
    </div >
  );
};

export default MainLayout;
