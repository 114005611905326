import { FC, ReactElement } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import Select, { SingleValue } from 'react-select'

import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { User, UpdateUser, UserPlan, UserRole, UserStatus } from "@services/user/model"

type UserFormPros = {
    user?: User
    onSave: (u: UpdateUser, cb: () => void) => void,
}

const UserForm: FC<UserFormPros> = ({ onSave, user }): ReactElement => {
    const methods = useForm<User>({ defaultValues: user })
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { isValid, isDirty }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const isEditMode = !!user

    const onSubmit: SubmitHandler<UpdateUser> = async (data) => {
        const onSaveResetForm = () => reset(getValues())

        onSave(data, onSaveResetForm)
    }

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <h3>{user?.email}</h3>
                </div>
                <div className="col-12">
                    <FormControlWrapper
                        label='Role'
                        name={getFieldName('role')}
                        {...makeCommonFormControlWrapperAttributes('role')}>
                        <Controller
                            name={getFieldName('role')}
                            render={({ field: { onChange, onBlur } }) =>
                                <Select
                                    options={getRoleOptions()}
                                    defaultValue={getRoleOption(user?.role)}
                                    onChange={onRoleSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    {...makeCommonFormControlAttributes('role')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Plan'
                        name={getFieldName('plan')}
                        {...makeCommonFormControlWrapperAttributes('plan')}>
                        <Controller
                            name={getFieldName('plan')}
                            render={({ field: { onChange, onBlur } }) =>
                                <Select
                                    options={getPlanOptions()}
                                    defaultValue={getPlanOption(user?.plan)}
                                    onChange={onPlanSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    {...makeCommonFormControlAttributes('plan')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Status'
                        name={getFieldName('status')}
                        {...makeCommonFormControlWrapperAttributes('status')}>
                        <Controller
                            name={getFieldName('status')}
                            render={({ field: { onChange, onBlur } }) =>
                                <Select
                                    options={getStatusOptions()}
                                    defaultValue={getStatusOption(user?.status)}
                                    onChange={onStatusSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    {...makeCommonFormControlAttributes('status')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        {isEditMode ? 'Salva' : 'Crea'}
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default UserForm


const PlanMapper: Record<UserPlan, string> = {
    'NO_PLAN': 'None',
    'FREE': 'Free',
    'FREELANCE': 'Freelance',
    'UNLIMITED': 'Unlimited',
}

function getPlanOptions() {
    return Object
        .keys(PlanMapper)
        .map(p => ({ value: p as UserPlan, label: PlanMapper[p as UserPlan] }))
}

function getPlanOption(plan?: UserPlan) {
    return plan && { value: plan, label: PlanMapper[plan] }
}

function onPlanSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: UserPlan, label: string }>) => {
        onChange(option?.value)
    }
}


const RoleMapper: Record<UserRole, string> = {
    'USER': 'User',
    'ADMIN': 'Administrator',
    'SUPER_ADMIN': 'System Administrator',
}

function getRoleOptions() {
    return Object
        .keys(RoleMapper)
        .map(r => ({ value: r as UserRole, label: RoleMapper[r as UserRole] }))
}

function getRoleOption(role?: UserRole) {
    return role && { value: role, label: RoleMapper[role] }
}

function onRoleSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: UserRole, label: string }>) => {
        onChange(option?.value)
    }
}

const StatusMapper: Record<UserStatus, string> = {
    'ACTIVE': 'Active',
    'DEACTIVATED': 'Deactivated',
    'INACTIVE': 'Inactive',
    'LOCKED_OUT': 'Locked out',
    'SUSPENDED': 'Suspended',
}

function getStatusOptions() {
    return Object
        .keys(StatusMapper)
        .map(r => ({ value: r as UserStatus, label: StatusMapper[r as UserStatus] }))
}

function getStatusOption(status?: UserStatus) {
    return status && { value: status, label: StatusMapper[status] }
}

function onStatusSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: UserStatus, label: string }>) => {
        onChange(option?.value)
    }
}