import React, { ReactElement, FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'

import { Country } from '@services/country/model'
import { City } from '@services/city/model'
import { State } from '@services/state/model'
import useCommonFormControlAttributes from '@hooks/useCommonFormControlAttributes'
import useCountries from '@hooks/useCountries'
import useCities from '@hooks/useCities'
import useStates from '@hooks/useStates'
import FormControlWrapper from '@components/atoms/FormControlWrapper'

const AddressForm: FC<{ type: string, title?: string }> = ({ type, title }): ReactElement => {
    const { register, watch, setValue } = useFormContext()
    const country: Country = watch(`${type}.country`)
    const state: State = watch(`${type}.state`)

    const { countries } = useCountries()
    const { states } = useStates({ country })
    const { cities } = useCities({ country, state })

    const { getFieldName, makeCommonFormControlAttributes, makeCommonFormControlWrapperAttributes } = useCommonFormControlAttributes(type)

    const getCountriesOptions = countries.map(c => ({ value: c.iso, label: c.name }))
    const getStatesOptions = states.map(s => ({ value: s.iso, label: s.name }))
    const getCitiesOptions = cities.map(c => ({ value: c.id, label: c.name }))

    const getSelectedCountryOption = (country?: Country) => country && { value: country.iso, label: countries.find(c => c.iso === country.iso)?.name || '' }
    const getSelectedStateOption = (state?: State) => state && { value: state.iso, label: states.find(s => s.iso === state.iso)?.name || '' }
    const getSelectedCityOption = (city?: City) => city && { value: city.id, label: cities.find(c => c.id === city.id)?.name || '' }

    const onCountryChangeFactory = (onChange: (event: any) => void) => {
        return (option: SingleValue<{ value: string, label: string }>) => {
            //RESET STATE SELECTED VALUE
            setValue(`${type}.state`, null)
            //RESET CITY SELECTED VALUE
            setValue(`${type}.city`, null)
            //TRIGGER onChange
            onChange(option && { iso: option?.value, name: option?.label })
        }
    }

    const onStateChangeFactory = (onChange: (event: any) => void) => {
        return (option: SingleValue<{ value: string, label: string }>) => {
            //RESET CITY SELECTED VALUE
            setValue(`${type}.city`, null)
            //TRIGGER onChange
            onChange(option && { iso: option?.value, name: option?.label })
        }
    }

    const onCityChangeFactory = (onChange: (event: any) => void) => {
        return (option: SingleValue<{ value: number, label: string }>) => {
            //TRIGGER onChange
            onChange(option && { id: option?.value, name: option?.label })
        }
    }

    return <>
        {
            title &&
            <div className="row mb-3">
                <div className="col">
                    <h3>{title}</h3>
                </div>
            </div>
        }
        <div className="row mb-3">
            <div className="col">
                <FormControlWrapper
                    label='Nazione'
                    name={getFieldName('country')}
                    {...makeCommonFormControlWrapperAttributes('country')}>
                    <Controller
                        name={getFieldName('country')}
                        render={({ field: { onChange, onBlur, value } }) =>
                            <Select
                                options={getCountriesOptions}
                                value={getSelectedCountryOption(value)}
                                onChange={onCountryChangeFactory(onChange)}
                                onBlur={onBlur}
                                isClearable
                                {...makeCommonFormControlAttributes('country', { id: { attributeName: 'inputId' } })}
                            />
                        }
                    />
                </FormControlWrapper>
            </div>
            <div className="col">
                <FormControlWrapper
                    label='Provincia'
                    name={getFieldName('state')}
                    {...makeCommonFormControlWrapperAttributes('state')}>
                    <Controller
                        name={getFieldName('state')}
                        render={({ field: { onChange, onBlur, value } }) =>
                            <Select
                                options={getStatesOptions}
                                value={getSelectedStateOption(value)}
                                onChange={onStateChangeFactory(onChange)}
                                onBlur={onBlur}
                                isClearable
                                {...makeCommonFormControlAttributes('state', { id: { attributeName: 'inputId' } })}
                            />
                        }
                    />
                </FormControlWrapper>
            </div>
            <div className="col">
                <FormControlWrapper
                    label='Città'
                    name={getFieldName('city')}
                    {...makeCommonFormControlWrapperAttributes('city')}>
                    <Controller
                        name={getFieldName('city')}
                        render={({ field: { onChange, onBlur, value } }) =>
                            <Select
                                value={getSelectedCityOption(value)}
                                onChange={onCityChangeFactory(onChange)}
                                onBlur={onBlur}
                                isClearable
                                options={getCitiesOptions}
                                {...makeCommonFormControlAttributes('city', { id: { attributeName: 'inputId' } })}
                            />
                        }
                    />
                </FormControlWrapper>
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormControlWrapper
                    label='Indirizzo'
                    name={getFieldName('address')}
                    {...makeCommonFormControlWrapperAttributes('address')}>
                    <input
                        type="text"
                        className="form-control"
                        {...makeCommonFormControlAttributes('address')}
                        {...register(getFieldName('address'))}
                    />
                </FormControlWrapper>
            </div>
            <div className="col-6 col-lg-3">
                <FormControlWrapper
                    label='CAP'
                    name={getFieldName('zip')}
                    {...makeCommonFormControlWrapperAttributes('zip')}>
                    <input
                        type="text"
                        className="form-control"
                        {...makeCommonFormControlAttributes('zip')}
                        {...register(getFieldName('zip'))}
                    />
                </FormControlWrapper>
            </div>
        </div>
    </>
}

export default AddressForm