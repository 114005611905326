import React, { ReactElement, FC, useState, useEffect } from 'react'

import Modal from '@components/molecules/modal/Modal'
import { api } from '@services/user/api'
import { UpdateUser, User } from '@services/user/model'
import usePagination from '@hooks/usePagination'
import UserTable from './UserTable'
import UserForm from './UserForm'

const UsersPage: FC = (): ReactElement => {
    const [users, setUsers] = useState<User[]>([])
    const [total, setTotal] = useState(-1)
    const { pagination, onChangePagination } = usePagination({ total })
    const [isOpen, setIsOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User>()

    useEffect(() => {
        (async () => {
            await fetchUsers()
        })()
    }, [pagination])

    const fetchUsers = async () => {
        const { data: u, meta } = await api.find({ pagination: { ...pagination } })

        setUsers(u)
        setTotal(meta.pagination.total)
    }

    const onEditHandler = async (u: User) => {
        setSelectedUser(u)
        setIsOpen(true)
    }

    const onDeleteHandler = async (u: User) => {
        if (await api.remove(u.id)) {
            await fetchUsers()
        }
    }

    const onSaveHandler = async (u: UpdateUser) => {
        const res = await api.update(u)

        if (res) {
            setSelectedUser(undefined)
            setIsOpen(false)
            await fetchUsers()
        }
    }

    const onCloseModalHandler = () => {
        setSelectedUser(undefined)
        setIsOpen(false)
    }

    return <>
        <div className="container-fluid">
            <div className="row py-3">
                <div className="col-12">
                    <UserTable
                        users={users}
                        pagination={pagination}
                        onChangePagination={onChangePagination}
                        total={total}
                        onEdit={onEditHandler}
                        onDelete={onDeleteHandler}
                    />
                </div>
            </div>
        </div>
        <Modal
            isOpen={isOpen}
            onRequestClose={onCloseModalHandler}>
            <UserForm
                user={selectedUser}
                onSave={onSaveHandler} />
        </Modal>
    </>
};

export default UsersPage