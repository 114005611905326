import React, { ReactElement, FC, useMemo } from 'react'
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { OnChangePaginationFn } from '@hooks/usePagination'
import TablePagination from '@components/organisms/table/TablePagination'
import Table from '@components/organisms/table/Table'
import { Pagination } from '@services/api.model'
import { Term } from '@services/taxonomy/model'

type TaxonomyTableProps = {
    terms: Term[]
    total: number
    onDelete: (term: Term) => void
    onUpdate: (term: Term) => void
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
}

const TaxonomyTable: FC<TaxonomyTableProps> = ({ terms, total, onDelete, onUpdate, pagination, onChangePagination }): ReactElement => {

    const columns = useMemo<ColumnDef<Term>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    <button className="btn btn-primary btn-sm" onClick={() => onUpdate(props.row.original)}>Modifica</button>
                    <button className="btn btn-danger btn-sm ms-1" onClick={() => onDelete(props.row.original)}>Elimina</button>
                </>
            }

        ], [])

    const table = useReactTable<Term>({
        data: terms,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
    })

    return <>
        <Table<Term> table={table} />
        <TablePagination<Term> table={table} onChangePagination={onChangePagination} />
    </>
}

export default TaxonomyTable