import { FC, ReactElement, useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { Term } from "@services/taxonomy/model"
import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"

type TaxonomyFormPros = {
    onSave: (t: InputFormData) => Promise<void>
    term?: Term
}

type InputFormData = {
    name: string
}

const TaxonomyForm: FC<TaxonomyFormPros> = ({ onSave, term }): ReactElement => {
    const methods = useForm<InputFormData>()
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, isDirty }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    useEffect(() => reset(toForm(term)), [term])

    const onSubmit: SubmitHandler<InputFormData> = async (data) => {
        await onSave({
            name: data.name
        })
    }

    const isEditMode = !!term

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
                <div className="col">
                    <FormControlWrapper
                        label='Nome*'
                        name={getFieldName('name')}
                        {...makeCommonFormControlWrapperAttributes('name')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('name')}
                            {...register('name', { required: true })}
                        />
                    </FormControlWrapper>
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        {isEditMode ? 'Salva' : 'Crea'}
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default TaxonomyForm

const toForm = (t?: Term): InputFormData => {
    return {
        name: t?.name || ''
    }
}