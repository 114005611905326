import React, { ReactElement, FC } from 'react'

import logo from '@assets/logo.svg'


const Logo: FC = (): ReactElement => {

    return <img src={logo} alt='Homup logo' width={`30px`}/>;
};

export default Logo