import { FC } from 'react'

const Loader: FC<any> = () => {
    return <div className="text-center p-3">
        <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
}

export default Loader