import { FC, PropsWithChildren, ReactElement } from 'react'

import Loader from './Loader'
import { ApiErrorType } from '@hooks/useApi'

type AsyncWrapperProps = {
    isPending: boolean
    error?: ApiErrorType
    render?: ReactElement
    onErrorRender?: ReactElement
}

const AsyncWrapper: FC<PropsWithChildren<AsyncWrapperProps>> = ({ children, isPending, error, render, onErrorRender }) => {
    if (isPending) {
        return render || <Loader />
    }

    if (error) {
        return onErrorRender || <AsyncError {...error} />
    }

    return <>{children}</>
}

export default AsyncWrapper

type AsyncErrorProps = ApiErrorType

const AsyncError: FC<AsyncErrorProps> = ({ error, message, status }) => {
    return <div className='container-fluid p-2'>
        <div className="row async-error p-3">
            <div className="col-12 text-center">
                <div className="h3">{error}</div>
                <div className="">{message}</div>
            </div>
        </div>
    </div>
}