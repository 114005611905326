import { redirect, createBrowserRouter } from "react-router-dom"

import { getJwtState, getCurrentUserState, setCurrentUserState } from "./state"
import MainLayout from "@components/layout/main/MainLayout"
import ActivatePage from "@pages/activate/ActivatePage"
import DashboardPage from "@pages/dashboard/DashboardPage"
import ErrorPage from "@pages/error/ErrorPage"
import LoginPage from "@pages/login/LoginPage"
import PatientsPage from "@pages/patient/PatientsPage"
import SignupPage from "@pages/signup/SignupPage"
import WorkspacePage from "@pages/workspace/WorkspacePage"
import ResetPasswordPage from "@pages/reset-password/ResetPasswordPage"
import PatientDetailPage from "@pages/patient/PatientDetailPage"
import PatientDashboard from "@pages/patient/PatientDashboard"
import ClinicalEpisodePage from "@pages/patient/clinical-episode/ClinicalEpisodePage"
import UsersPage from "@pages/users/UsersPage"
import ExerciseListPage from "@pages/exercise/ExerciseListPage"
import TaxonomyPage from "@pages/exercise/taxonomy/TaxonomyPage"
import ExerciseDetailPage from "@pages/exercise/ExerciseDetailPage"
import WorkoutPlan from "@pages/patient/workout-plan/WorkoutPlan"
import WorkoutPlanDashboard from "@pages/patient/workout-plan/WorkoutPlanDashboard"
import AccountPage from "@pages/account/AccountPage"
import CalendarPage from "@pages/calendar/CalendarPage"
import PublicWorkoutPlan from "@pages/public/workout-plan/WorkoutPlan"

import { api as userApi } from "@services/user/api"
import { api as patientApi } from "@services/patient/api"
import { api as exerciseApi } from "@services/exercise/exercise.api"
import { api as workoutPlanApi } from "@services/workout-plan/api"
import { api as mediaApi } from "@services/media/api"
import { TAXONOMY_LIST } from "@services/exercise/taxonomies.model"
import { Exercise } from "@services/exercise/exercise.model"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    loader: authenticateRoute,
    handle: {
      title: 'Home'
    },
    children: [
      {
        path: "dashboard",
        loader: withTenantRoute,
        element: <DashboardPage />,
        handle: {
          title: 'Dashboard'
        }
      },
      {
        path: "workspace",
        element: <WorkspacePage />,
        handle: {
          title: 'Workspaces'
        }
      },
      {
        path: "account",
        element: <AccountPage />,
        handle: {
          title: 'Account'
        }
      },
      {
        path: "users",
        loader: withSuperAdminRoute,
        element: <UsersPage />,
        handle: {
          title: 'Users'
        }
      },
      {
        path: "exercises",
        loader: withAdminRoute,
        handle: {
          title: 'Exercises'
        },
        children: [
          {
            index: true,
            element: <ExerciseListPage />,
          },
          {
            path: 'new',
            element: <ExerciseDetailPage />,
            handle: {
              title: 'New'
            }
          },
          {
            path: ':exerciseId',
            loader: async ({ params }) => {
              const { exerciseId } = params

              const exercise = (exerciseId && await exerciseApi.findBy(exerciseId)) || undefined
              const mediaList = (exerciseId && await mediaApi.findByEntityId(exerciseId)) || []

              return { exercise, mediaList }
            },
            handle: {
              title: (e: Exercise) => <span>{e.name}</span>
            },
            element: <ExerciseDetailPage isEdit={true} />
          },
          ...exerciseTaxonomiesRoutes()
        ]
      },
      {
        path: "patients",
        loader: withTenantRoute,
        handle: {
          title: 'Patients'
        },
        children: [
          {
            index: true,
            element: <PatientsPage />,
          },
          {
            path: "new",
            element: <PatientDetailPage />,
            handle: {
              title: 'New'
            }
          },
          {
            path: ":id",
            id: "patient-index",
            loader: async (args) => {
              const patientId = args.params.id as string
              const patient = await patientApi.findBy(patientId)
              return { patient }
            },
            handle: {
              title: (data: any) => <span>{`${data.patient.lastName} ${data.patient.firstName}`}</span>
            },
            children: [
              {
                index: true,
                element: <PatientDashboard />,
              },
              {
                path: "edit",
                element: <PatientDetailPage />,
                handle: {
                  title: 'Edit'
                }
              },
              {
                path: "clinical-episode",
                children: [
                  {
                    path: 'new',
                    element: <ClinicalEpisodePage />,
                  },
                  {
                    path: ':clinicalEpisodeId',
                    children: [
                      {
                        path: 'edit',
                        element: <ClinicalEpisodePage />
                      }
                    ]
                  }
                ]
              },
              {
                path: "workout-plan",
                children: [
                  {
                    path: 'new',
                    element: <WorkoutPlan />,
                  },
                  {
                    path: ':workoutPlanId',
                    id: "workout-plan-index",
                    loader: async (args) => {
                      const id = args.params.workoutPlanId as string
                      const workoutPlan = await workoutPlanApi.findBy(id)

                      return { workoutPlan }
                    },
                    children: [
                      {
                        index: true,
                        element: <WorkoutPlanDashboard />,
                      },
                      {
                        path: 'edit',
                        element: <WorkoutPlan />
                      }
                    ]
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        path: "calendar",
        loader: withTenantRoute,
        element: <CalendarPage />,
        handle: {
          title: 'Calendar'
        }
      },
    ]
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/activate",
    element: <ActivatePage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: '/public',
    children: [
      {
        path: ":tenantId/workout-plan/:workoutPlanId",
        element: <PublicWorkoutPlan />,
        loader: async ({ params }) => {
          const { tenantId, workoutPlanId } = params

          const workoutPlan = await workoutPlanApi.publicFindBy(workoutPlanId!, tenantId!)

          return { workoutPlan }
        }
      }
    ]
  }
])

async function authenticateRoute() {
  const jwtState = getJwtState()

  if (!jwtState || !jwtState.token || !jwtState.decoded) {
    return redirect("/login")
  }

  try {
    const currentUser = getCurrentUserState()

    if (currentUser) {
      return null
    }

    const user = await userApi.me()

    setCurrentUserState({ user, tenantId: user.tenants[0]?.id })
  } catch (error) {
    console.log({ error })
  }

  return null
}

async function withTenantRoute() {
  const currentUserState = getCurrentUserState()

  if (!currentUserState || !currentUserState.tenantId) {
    return redirect("/workspace")
  }

  return null
}

async function withSuperAdminRoute() {
  const currentUserState = getCurrentUserState()

  if (currentUserState?.user.role === 'SUPER_ADMIN') {
    return null
  }

  return redirect("/dashboard")
}

async function withAdminRoute() {
  const currentUserState = getCurrentUserState()

  if (currentUserState?.user.role === 'SUPER_ADMIN'
    || currentUserState?.user.role === 'ADMIN') {
    return null
  }

  return redirect("/dashboard")
}

function exerciseTaxonomiesRoutes() {
  return TAXONOMY_LIST.map(t => ({
    path: `taxonomies/${t.id}`,
    element: <TaxonomyPage id={t.id} />,
    handle: {
      title: t.name
    }
  }))
}