import { FC, useState } from "react"
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom"

import useApi from "@hooks/useApi"
import { WorkoutPlan } from "@services/workout-plan/model"
import { api } from "@services/workout-plan/api"
import AsyncWrapper from "@components/atoms/AsyncWrapper"

const WorkoutPlanDashboard: FC = () => {
    const navigate = useNavigate()
    const { workoutPlan } = useRouteLoaderData('workout-plan-index') as { workoutPlan: WorkoutPlan }
    const { id: patientId, workoutPlanId } = useParams()
    const [noticeMessage, setNoticeMessage] = useState<string>()
    const { run: sendNotice, ...sendNoticeStatus } = useApi({ callback: async (args) => await api.sendNotice(args[0] as string) })
    const { run: deleteWorkoutPlan, ...deleteWorkoutPlanStatus } = useApi({ callback: async (args) => await api.remove(args[0] as string) })

    const goToWorkoutPlanEditPage = () => navigate(`/patients/${patientId}/workout-plan/${workoutPlanId}/edit`)

    const deleteHandler = async () => {
        await deleteWorkoutPlan(workoutPlan.id)

        if (workoutPlan.clinicalEpisodeId) {
            navigate(`/patients/${patientId}/clinical-episode/${workoutPlan.clinicalEpisodeId}/edit`)
        } else {
            navigate(`/patients/${patientId}`)
        }
    }

    const sendEmailToPatientWithWorkout = async () => {
        const isSent = await sendNotice(workoutPlan.id)

        setNoticeMessage(isSent ? 'Email inviata!' : 'Errore nell\'invio della mail')
    }

    return <>
        <AsyncWrapper {...deleteWorkoutPlanStatus}>
            <AsyncWrapper {...sendNoticeStatus}>
                <div className="border-bottom pb-3">
                    <div className="h3">Dashboard scheda: <strong>{workoutPlan.name}</strong></div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm me-2"
                            onClick={sendEmailToPatientWithWorkout}>
                            Invia scheda
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm me-2"
                            onClick={goToWorkoutPlanEditPage}>
                            Modifica
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={deleteHandler}>
                            Elimina
                        </button>
                    </div>
                    <div>{noticeMessage}</div>
                </div>
                <div className="py-3">
                    <div className="h3">Statistiche scheda (da implementare!)</div>
                    <ul className="list-group">
                        <li className="list-group-item">Calendario con schede eseguite/non eseguite</li>
                        <li className="list-group-item">Statisctiche generali scheda (???)</li>
                        <li className="list-group-item">Statistiche per ogni esercizio (trend ripetizioni, trend velocità di esecuzione, feedback dell'utente,...)</li>
                    </ul>
                </div>
            </AsyncWrapper>
        </AsyncWrapper>
    </>
}

export default WorkoutPlanDashboard