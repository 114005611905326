import { FC } from "react"

import './Exercise.scss'

import { PublicWorkoutPlanExercise, PublicWorkoutPlanExerciseMedia } from "@services/workout-plan/workout-plan-exercise/model"
import { Checkbox } from "@components/atoms/Checkbox/Checkbox"
import { Media } from "@components/organisms/media/Media"

type ExerciseProps = {
    exercise: PublicWorkoutPlanExercise
    onShowDescription: (note: string) => void
}

const Exercise: FC<ExerciseProps> = ({ exercise: workoutExercise, onShowDescription }) => {
    const featuredMedia: PublicWorkoutPlanExerciseMedia | undefined = workoutExercise.exercise.media.find(m => m.type === 'featured') || workoutExercise.exercise.media.find(m => m.type === 'gallery')

    return <div className="exercise shadow rounded">
        <div className="exercise__info">
            <div className="exercise__info-box">
                <div className="info-box">
                    <div className="info-box__number">{workoutExercise.sets}</div>
                    <div className="info-box__description">Serie</div>
                </div>
            </div>
            <div className="exercise__info-box">
                <div className="info-box">
                    <div className="info-box__number">{workoutExercise.reps}</div>
                    <div className="info-box__description">Ripetizioni</div>
                </div>
            </div>
            <div className="exercise__info-box">
                <div className="info-box">
                    <div className="info-box__number info-box__number--seconds">{workoutExercise.rest}</div>
                    <div className="info-box__description">Riposo</div>
                </div>
            </div>
        </div>
        <div className="exercise__media">
            <Media media={featuredMedia} ar={{ width: 4, height: 5 }} />
            <div className="exercise__title">
                {workoutExercise.exercise.name}
            </div>
            <div className="exercise__description">
                <i className="bi bi-info-circle" title="Description" onClick={onShowDescription.bind(this, workoutExercise.exercise.description)} />
            </div>
            <div className="exercise__days">
                {Object.entries(workoutExercise.days)
                    .filter(([, d]) => d)
                    .map(([idx]) => (idx === '1' && 'LUN')
                        || (idx === '2' && 'MAR')
                        || (idx === '3' && 'MER')
                        || (idx === '4' && 'GIO')
                        || (idx === '5' && 'VEN')
                        || (idx === '6' && 'SAB')
                        || (idx === '7' && 'DOM')
                    )
                    .map(d => <span key={`day-${d}`} className="exercise__days-item">{d}</span>)
                }
            </div>
        </div>
        <div className="exercise__body">

            {workoutExercise.notes && <div className="exercise__body-note">
                <div className="note__header">Note:</div>
                <div className="note__description" dangerouslySetInnerHTML={{ __html: workoutExercise.notes.replaceAll('\n', '<br />') }} />
            </div>}

            <div className="sets-progress">
                <div className="sets-progress__header">
                    Avanzamento
                </div>
                <div className="sets-progress__items-container">
                    {
                        Array.from(Array(workoutExercise.sets).keys())
                            .map(s => <div key={s + 1} className="sets-progress__item">
                                <Checkbox id={`${workoutExercise.id}-sets-${s + 1}`} />
                            </div>)
                    }
                </div>
            </div>

        </div>

    </div>
}

export { Exercise }