import React, { ReactElement, FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'

import api from '@services/auth.service'

type FormData = {
  email: string
}

const RequestResetForm: FC = (): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>()

  const [errorMessage, setErrorMessage] = useState('')
  const [isSent, setIsSent] = useState(false)

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {

      await api.resetPassword(data)

      setIsSent(true)
      
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.message || error.message)
      }
    }
  }

  if (isSent) {
    return <>
      <h3>
        Abbiamo spedito il link per resettare la password alla tua casella di posta. <br />
        Controlla la tua casella.
      </h3>
    </>
  } else {
    return <>
      <form onSubmit={handleSubmit(onSubmit)}
        className='border rounded-1 p-4'>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            {...register("email", { required: true })}
          />
          <div id="emailHelpBlock" className="form-text">
            {errors.email && <span>This field is required.</span>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className='btn btn-primary'
            disabled={!isValid}>
            Resetta
          </button>
        </div>
        <div className='mt-3'>
          {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
        </div>
      </form>
    </>
  }
};

export default RequestResetForm;
