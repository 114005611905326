import { FC, ReactElement } from "react"
import { Interval } from "luxon"

import { Appointment } from "@/services/calendar/appointment/model"
import { DATE_TIME_FORMAT } from "@/services/constants"

type PropType = {
    list: Appointment[]
    onClick?: (appointment: Appointment) => void
}

export const AppointmentsList: FC<PropType> = ({ list, onClick }): ReactElement => {
    const onClickHandler = (appointment: Appointment) => {
        onClick && onClick(appointment)
    }

    return <div className="list-group">
        {
            list.map(a => {
                const i = Interval.fromDateTimes(a.start, a.end).toDuration(['hours', 'minutes'])
                const isPast = a.end.diffNow().milliseconds <= 0
                let durationText

                if (i.hours > 1) {
                    durationText = i.toHuman()
                } else if (i.hours === 1 && i.minutes === 0) {
                    durationText = '1 ora'
                } else {
                    durationText = `${i.minutes} minuti`
                }

                return (
                    <button
                        type="button"
                        className={`list-group-item list-group-item-action ${isPast ? 'list-group-item-secondary' : ''}`}
                        key={a.id}
                        onClick={onClickHandler.bind(this, a)}
                    >
                        <span>{a.start.toFormat(DATE_TIME_FORMAT)}</span> &nbsp;
                        <span className="badge bg-primary">{durationText}</span> &nbsp;
                        {a.title}
                    </button>
                )
            })
        }
    </div>
}