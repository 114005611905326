import { FC } from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"
import { useAtom } from "jotai/react"

import Logo from "../Logo"
import { currentUserState, setCurrentUserState, setJwtState } from '@/state'
import { TAXONOMY_LIST } from "@services/exercise/taxonomies.model"

type SidebarProps = {

}

const Sidebar: FC<SidebarProps> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [currentUser] = useAtom(currentUserState)
    const isTaxonomiesMenuOpen = location.pathname.includes('/exercises')

    const logout = () => {
        setJwtState(null)
        setCurrentUserState(null)
        navigate('/login')
    }

    return (
        <aside className='sidebar'>
            <div className="py-5 d-flex justify-content-center align-items-center">
                <Logo />
            </div>
            <nav className="h-100 d-flex flex-column">
                <ul className="nav flex-column mb-auto">
                    <li className="nav-item">
                        <NavLink to={`dashboard`} className="nav-link">Dashboard</NavLink>
                    </li>
                    {

                        'SUPER_ADMIN' === currentUser?.user.role &&
                        <>
                            <li className="nav-item">
                                <NavLink to={`users`} className="nav-link">Users</NavLink>
                            </li>
                        </>
                    }
                    {

                        ('SUPER_ADMIN' === currentUser?.user.role
                            || 'ADMIN' === currentUser?.user.role) &&
                        <>
                            <li className="nav-item">
                                <NavLink to={`exercises`} className="nav-link">Exercises</NavLink>
                            </li>
                            {
                                isTaxonomiesMenuOpen && TAXONOMY_LIST.map(t =>
                                    <li key={t.id} className="nav-item">
                                        <NavLink to={`exercises/taxonomies/${t.id}`} className="nav-link ms-4">{t.name}</NavLink>
                                    </li>
                                )
                            }
                        </>
                    }
                    <li className="nav-item">
                        <NavLink to={`patients`} className="nav-link">Pazienti</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`calendar`} className="nav-link">Calendario</NavLink>
                    </li>
                </ul>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink to={`account`} className="nav-link">Account</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`workspace`} className="nav-link">Workspaces</NavLink>
                    </li>
                    <li className="nav-item">
                        <button className='btn btn-link nav-link' onClick={logout}>Logout</button>
                    </li>
                </ul>
            </nav>
        </aside>
    )
}

export default Sidebar