import { useId } from "react"

type FormControlAttributeType = {
    attributeName: string
} | boolean

type CommonFormControlAttributesHookType = (parentFieldName?: string) => {
    getFieldName: (fieldName: string) => string
    makeID: (fieldName: string) => string
    makeLabelID: (fieldName: string) => string
    makeDescriptionID: (fieldName: string) => string
    makeCommonFormControlAttributes: (fieldName: string, config?: Record<'id', FormControlAttributeType>) => { id?: string, 'aria-labelledby'?: string, 'aria-describedby'?: string }
    makeCommonFormControlWrapperAttributes: (fieldName: string) => { id: string, labelId: string, descriptionId: string }
}

const useCommonFormControlAttributes: CommonFormControlAttributesHookType = (parentFieldName) => {
    const id = useId()

    const normalizedParentFieldName = parentFieldName ? `${parentFieldName.replaceAll('.', '-')}-` : ''

    const getFieldName = (fieldName: string) => {
        return parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName
    }

    const makeID = (fieldName: string) => {
        return `${normalizedParentFieldName}${fieldName}-id-${id}`
    }

    const makeLabelID = (fieldName: string) => {
        return `${normalizedParentFieldName}${fieldName}-label-${id}`
    }

    const makeDescriptionID = (fieldName: string) => {
        return `${normalizedParentFieldName}${fieldName}-description-${id}`
    }

    const makeCommonFormControlAttributes = (fieldName: string, config?: Record<'id', FormControlAttributeType>) => {

        let idAttributeName: boolean | string = 'id'

        if (config && config.id === false) {
            idAttributeName = false
        } else if (config && config.id) {
            idAttributeName = config.id === true ? 'id' : config.id.attributeName
        }

        return {
            ...(idAttributeName && { [idAttributeName]: makeID(fieldName) }),
            //'aria-labelledby': makeLabelID(fieldName),
            'aria-describedby': makeDescriptionID(fieldName)
        }
    }

    const makeCommonFormControlWrapperAttributes = (fieldName: string) => {
        return {
            id: makeID(fieldName),
            labelId: makeLabelID(fieldName),
            descriptionId: makeDescriptionID(fieldName)
        }
    }

    return {
        getFieldName,
        makeID,
        makeLabelID,
        makeDescriptionID,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    }
}

export default useCommonFormControlAttributes