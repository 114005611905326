import { FC } from 'react'
import { FieldPathValue, Noop } from 'react-hook-form'
import Select, { MultiValue } from 'react-select'

import useCommonFormControlAttributes from '@hooks/useCommonFormControlAttributes'

const SELECT_DAYS_PH = 'Seleziona giorni'

const DAYS = {
    "1": "Lun",
    "2": "Mar",
    "3": "Mer",
    "4": "Gio",
    "5": "Ven",
    "6": "Sab",
    "7": "Dom",
}

type SelectWeekdaysProps = {
    field: string
    required?: boolean
    onChange: (...event: any[]) => void
    onBlur: Noop
    value: FieldPathValue<any, any>
}

export const SelectWeekdays: FC<SelectWeekdaysProps> = ({ field, required, onChange, onBlur, value }) => {
    //const { register, watch, setValue } = useFormContext()
    const {
        makeCommonFormControlAttributes
    } = useCommonFormControlAttributes()

    return <Select
        isMulti
        options={getDaysOptions()}
        value={transformDaysToOptions(value)}
        onChange={onMultiDaysSelectChangeFactory(onChange)}
        onBlur={onBlur}
        isClearable
        placeholder={SELECT_DAYS_PH}
        {...(required && { required })}
        {...makeCommonFormControlAttributes(field, { id: { attributeName: 'inputId' } })}
    />
}

const getDaysOptions = () => {
    return Object.keys(DAYS)
        // @ts-ignore
        .map(k => ({ value: parseInt(k), label: DAYS[k] }))

}

const transformDaysToOptions = (days?: number[]) => {
    if (!days) {
        return []
    }

    return Object
        .keys(DAYS)
        .filter(k => days.includes(parseInt(k)))
        // @ts-ignore
        .map(k => ({ value: parseInt(k), label: DAYS[k] }))
}

function onMultiDaysSelectChangeFactory(onChange: (event: any) => void) {
    return (options: MultiValue<{ value: number, label: string }>) => {
        onChange(options.map(o => o.value))
    }
}