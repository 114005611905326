export type TAXONOMY_ID = 'therapeutic-goal' | 'muscle' | 'movement' | 'joint' | 'equipment' | 'disease'

export const TAXONOMY_LIST: Array<{ id: TAXONOMY_ID, name: string }> = [
    {
        id: 'disease',
        name: 'Disease'
    },
    {
        id: 'equipment',
        name: 'Equipment'
    },
    {
        id: 'joint',
        name: 'Joint'
    },
    {
        id: 'movement',
        name: 'Movement'
    },
    {
        id: 'muscle',
        name: 'Muscle'
    },
    {
        id: 'therapeutic-goal',
        name: 'Therapeutic goal'
    }
]