import React, { ReactElement, FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'

import api from '@services/auth.service'
import AuthFormWrapper from '@components/layout/AuthFormWrapper'
import { PASSWORD_PATTERN } from '@services/constants'

type FormData = {
    email: string
    password: string
    privacy: boolean
}

const SignupPage: FC = (): ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<FormData>()

    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSucces] = useState(false)

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        try {

            await api.signup(data)

            setIsSucces(true)
            
        } catch (error: unknown) {

            if (error instanceof AxiosError) {
                if (error.response?.status === 409) {
                    setErrorMessage('Utente già presente')
                } else {
                    setErrorMessage(error.response?.data.message || error.message)
                }
            }
        }
    }

    return <>
        <AuthFormWrapper>
            {isSuccess
                ? <h3>
                    Utente creato con successo. <br />
                    Controlla la tua mail per attivare l'account
                </h3>
                : <>
                    <form onSubmit={handleSubmit(onSubmit)}
                        className='border rounded-1 p-4'>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                {...register("email", { required: true })}
                            />
                            <div id="emailHelpBlock" className="form-text">
                                {errors.email && <span>This field is required.</span>}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inputPassword5" className="form-label">Password</label>
                            <input
                                type="password"
                                id="inputPassword5"
                                className="form-control"
                                aria-labelledby="passwordHelpBlock"
                                {...register("password", { required: true, pattern: PASSWORD_PATTERN })}
                            />
                            <div id="passwordHelpBlock" className="form-text">
                                La password deve essere lunga almeno 8 caratteri, contenere almeno
                                una lettera maiuscola, una minuscola, un numero, un carattere speciale (!@#$%^&*-_)
                                e non deve contenere spazi o emoji.
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="privacyCheckbox" {...register("privacy", { required: true })} />
                                <label className="form-check-label" htmlFor="privacyCheckbox">
                                    Accetto la privacy policy
                                </label>
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className='btn btn-primary'
                                disabled={!isValid}>
                                Registrati
                            </button>
                        </div>
                        <div className='mt-3'>
                            {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
                        </div>
                    </form>
                    <div className='pt-3'>Se hai già un account <Link to="/login">accedi</Link></div>
                </>
            }
        </AuthFormWrapper>
    </>
};

export default SignupPage