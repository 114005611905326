import { APIClient, client } from '@services/api'
import { APIClientConfig } from '@services/api.model'

import { WorkoutPlan, CreateWorkoutPlan, UpdateWorkoutPlan, WorkoutPlanResponse, PublicWorkoutPlan, PublicWorkoutPlanResponse } from './model'
import { WorkoutPlanExercise } from './workout-plan-exercise/model'
import { DateTime } from 'luxon'

class WorkoutPlanAPI extends APIClient<CreateWorkoutPlan, WorkoutPlan, UpdateWorkoutPlan, unknown, unknown, WorkoutPlanResponse> {

    public async publicFindBy(id: string, tenantId: string): Promise<PublicWorkoutPlan | undefined> {
        const { data } = await client.get<PublicWorkoutPlanResponse>(`/public/workout-plans/${id}`, { headers: { 'x-tenant-id': tenantId } })

        return {
            ...data,
            validity: {
                startDate: DateTime.fromISO(data.validity.startDate),
                endDate: DateTime.fromISO(data.validity.endDate),
            },
            exercises: data.exercises.map(wpEx => ({
                ...wpEx,
                exercise: {
                    ...wpEx.exercise,
                    media: wpEx.exercise.media.map(m => ({
                        ...m,
                        url: new URL(m.url)
                    }))
                }
            }))
        }
    }

    public async sendNotice(id: string): Promise<boolean> {
        const { status } = await client.post(`${this.path}/${id}/notice`, {})

        return status === 201
    }

    protected fromResponseData(data: WorkoutPlanResponse): WorkoutPlan {
        const exercises = data.exercises.map(e => ({
            id: e.id,
            workoutPlanId: e.workoutPlanId,
            exerciseId: e.exerciseId,
            sets: e.sets,
            reps: e.reps,
            rest: e.rest,
            days: {
                "1": e.days["1"],
                "2": e.days["2"],
                "3": e.days["3"],
                "4": e.days["4"],
                "5": e.days["5"],
                "6": e.days["6"],
                "7": e.days["7"],
            },
            ...(e.notes && { notes: e.notes }),
            ...(e.createdAt && { createdAt: new Date(e.createdAt) }),
            ...(e.updatedAt && { updatedAt: new Date(e.updatedAt) }),
        } as WorkoutPlanExercise))

        return {
            id: data.id,
            name: data.name,
            validity: {
                startDate: DateTime.fromISO(data.validity.startDate),
                endDate: DateTime.fromISO(data.validity.endDate),
            },
            notificationsEnabled: data.notificationsEnabled,
            days: {
                "1": data.days["1"],
                "2": data.days["2"],
                "3": data.days["3"],
                "4": data.days["4"],
                "5": data.days["5"],
                "6": data.days["6"],
                "7": data.days["7"],
            },
            timeOfDay: DateTime.fromISO(data.timeOfDay),
            exercises,
            ...(data.clinicalEpisodeId && { clinicalEpisodeId: data.clinicalEpisodeId }),
            ...(data.patientId && { patientId: data.patientId }),
            ...(data.notes && { notes: data.notes }),
        } as WorkoutPlan
    }

    protected toPayload(resource: CreateWorkoutPlan | WorkoutPlan): string | Object {
        const data = JSON.parse(JSON.stringify(resource))

        return {
            ...data,
            ...(resource.validity && {
                validity: {
                    ...(resource.validity.startDate && { startDate: resource.validity.startDate.toISODate() }),
                    ...(resource.validity.endDate && { endDate: resource.validity.endDate.toISODate() }),
                }
            }),
            ...(resource.timeOfDay && { timeOfDay: resource.timeOfDay.toISOTime() })
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/workout-plans'
}

export const api = new WorkoutPlanAPI(CONFIG)