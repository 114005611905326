import React, { ReactElement, FC, useState, useEffect } from 'react'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { DateTime } from 'luxon'

import { Patient } from '@services/patient/model'

import { api as ceApi } from '@services/clinical-episode/api'
import { ClinicalEpisode, UpdateClinicalEpisode } from '@services/clinical-episode/model'

import AsyncWrapper from '@components/atoms/AsyncWrapper'
import useApi from '@hooks/useApi'
import TherapySessionsWidget from './clinical-episode/therapy-session/TherapySessionsWidget'
import { api as appointmentApi } from '@services/calendar/api'
import WorkoutPlansWidget from './workout-plan/WorkoutPlansWidget'
import { Appointment } from '@/services/calendar/appointment/model'
import { AppointmentsList } from '@/components/molecules/appointments-list'

const PatientDashboard: FC = (): ReactElement => {
    const navigate = useNavigate()
    const { patient } = useRouteLoaderData('patient-index') as { patient: Patient }
    const [clinicalEpisodes, setClinicalEpisodes] = useState<ClinicalEpisode[]>()
    const lastClinicalEpisodeId: string | undefined = (clinicalEpisodes && clinicalEpisodes.length > 0 && clinicalEpisodes[0].id) || undefined

    const { run: fetchEpisodes, ...fetchEpisodesStatus } = useApi({ callback: async (id: string) => await ceApi.find({ filters: { patientId: { eq: id } } }) })
    const { run: updateEpisode, ...updateEpisodeStatus } = useApi({ callback: async (args) => await ceApi.update(args[0]) })
    const { run: fetchAppointments } = useApi({
        callback: async () => await appointmentApi.find({
            filters: {
                start: { gte: DateTime.now().toISODate() },
                status: { neq: 'cancelled' },
                patientId: { eq: patient.id }
            },
            sort: {
                start: 'ASC',
                end: 'ASC'
            },
            pagination: {
                page: 0,
                perPage: -1
            }
        })
    })
    const [appointments, setAppointments] = useState<Appointment[]>([])

    useEffect(() => {
        (async () => {
            const res = await fetchAppointments()

            setAppointments(res.data)
        })()
    }, [])

    useEffect(() => {
        (async function getClinicalEpisodes() {
            await fetchClinicalEpisodes()
        })()
    }, [patient])

    const archiveClinicalEpisode = (clinicalEpisodeId: string) => {
        (async function getClinicalEpisodes() {

            const updateClinicalEpisode: UpdateClinicalEpisode = {
                id: clinicalEpisodeId,
                status: 'archived'
            }

            await updateEpisode(updateClinicalEpisode)
            await fetchClinicalEpisodes()
        })()
    }

    const fetchClinicalEpisodes = async () => {
        if (!patient) {
            return
        }

        const ce = await fetchEpisodes(patient.id)

        ce && setClinicalEpisodes(ce.data)
    }

    return <>{
        patient &&
        <div className="container-fluid">
            <div className="row py-3 g-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body position-relative">
                            <div className='position-absolute top-0 end-0'>
                                <button
                                    className='btn btn-primary m-1'
                                    onClick={() => navigate(`/patients/${patient.id}/edit`)}>
                                    Modifica
                                </button>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>{`${patient.lastName} ${patient.firstName}`}</h3>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div><strong>Età:</strong> {patient.birthdate ? Math.floor(DateTime.fromJSDate(patient.birthdate).diffNow('years').years * -1) : '-'}</div>
                                        <div><strong>Data di nascita:</strong> {patient.birthdate?.toLocaleDateString()}</div>
                                        <div><strong>Email:</strong> {patient.email}</div>
                                        <div><strong>Telefono:</strong> {patient.phone}</div>
                                        <div><strong>Consensi:</strong> - </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div><strong>Sports & Hobbies:</strong> {patient.sports || '-'}</div>
                                        <div><strong>Professione:</strong> {patient.jobs || '-'} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex justify-content-between align-items-center py-1'>
                                <h5 className="card-title">Episodi clinici</h5>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigate(`/patients/${patient.id}/clinical-episode/new`)}>+</button>
                            </div>
                            <AsyncWrapper {...fetchEpisodesStatus}>
                                <AsyncWrapper {...updateEpisodeStatus}>
                                    <ul className="list-group">
                                        {
                                            clinicalEpisodes
                                            && clinicalEpisodes
                                                .map(ce => <li
                                                    key={ce.id}
                                                    className="list-group-item">
                                                    {ce.name}
                                                    <span className={`badge ms-2 ${ce.status === 'active' ? 'bg-primary' : 'bg-secondary'}`}>
                                                        {ce.status}
                                                    </span>
                                                    <div className="d-grid gap-2 d-md-block float-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => navigate(`/patients/${patient.id}/clinical-episode/${ce.id}/edit`)}>Modifica</button>
                                                        {
                                                            ce.status === 'active' &&
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary btn-sm"
                                                                onClick={archiveClinicalEpisode.bind(this, ce.id)}>Archivia</button>
                                                        }
                                                    </div>
                                                </li>)
                                        }
                                    </ul>
                                </AsyncWrapper>
                            </AsyncWrapper>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex justify-content-between align-items-center py-1'>
                                <h5 className="card-title">Diario ultimo episodio clinico</h5>
                            </div>
                            <TherapySessionsWidget clinicalEpisodeId={lastClinicalEpisodeId} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex justify-content-between align-items-center py-1'>
                                <h5 className="card-title">Schede esercizi</h5>
                            </div>
                            <WorkoutPlansWidget patientId={patient.id} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Appuntamenti</h5>
                            {appointments && <AppointmentsList list={appointments} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
};

export default PatientDashboard