import { FC, ReactElement, useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"

import { ClinicalEpisode, CreateClinicalEpisode, UpdateClinicalEpisode } from "@services/clinical-episode/model"
import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"

type ClinicalEpisodeFormPros = {
    clinicalEpisode?: ClinicalEpisode
    onSave: (ce: CreateClinicalEpisode | UpdateClinicalEpisode, cb: () => void) => void,
}

const ClinicalEpisodeForm: FC<ClinicalEpisodeFormPros> = ({ onSave, clinicalEpisode }): ReactElement => {
    const methods = useForm<CreateClinicalEpisode | UpdateClinicalEpisode>()
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { isValid }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    useEffect(() => {
        reset(clinicalEpisode)
    }, [clinicalEpisode])

    const onSubmit: SubmitHandler<CreateClinicalEpisode | UpdateClinicalEpisode> = async (data) => {
        const onSaveResetForm = () => reset(getValues())

        onSave(data, onSaveResetForm)
    }

    const isEditMode = !!clinicalEpisode

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <FormControlWrapper
                        label='Nome'
                        name={getFieldName('name')}
                        {...makeCommonFormControlWrapperAttributes('name')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('name')}
                            {...register('name')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Anamnesi'
                        name={getFieldName('medicalHistory')}
                        {...makeCommonFormControlWrapperAttributes('medicalHistory')}>
                        <textarea
                            rows={6}
                            className="form-control"
                            {...makeCommonFormControlAttributes('medicalHistory')}
                            {...register('medicalHistory')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Farmaci'
                        name={getFieldName('drugs')}
                        {...makeCommonFormControlWrapperAttributes('drugs')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('drugs')}
                            {...register('drugs')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Note aggiuntive'
                        name={getFieldName('notes')}
                        {...makeCommonFormControlWrapperAttributes('notes')}>
                        <textarea
                            rows={6}
                            className="form-control"
                            {...makeCommonFormControlAttributes('notes')}
                            {...register('notes')}
                        />
                    </FormControlWrapper>
                </div>


                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid}>
                        {isEditMode ? 'Salva' : 'Crea'}
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default ClinicalEpisodeForm