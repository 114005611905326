import React, { ReactElement, FC, useState, useEffect } from 'react'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import usePagination from '@hooks/usePagination'
import { ClinicalEpisode, CreateClinicalEpisode, UpdateClinicalEpisode } from '@services/clinical-episode/model'
import { CreateTherapySession, TherapySession } from '@services/clinical-episode/therapy-session/model'
import { api } from '@services/clinical-episode/api'
import { api as tsApi } from '@services/clinical-episode/therapy-session/api'
import Modal from '@components/molecules/modal/Modal'
import ClinicalEpisodeForm from './ClinicalEpisodeForm'
import TherapySessionTable from './therapy-session/TherapySessionTable'
import TherapySessionForm from './therapy-session/TherapySessionForm'
import WorkoutPlansWidget from '../workout-plan/WorkoutPlansWidget'

const ClinicalEpisodePage: FC = (): ReactElement => {

    const [isUpdateMode, setIsUpdateMode] = useState(false)
    const {
        id: patientId,
        clinicalEpisodeId
    } = useParams()
    const navigate = useNavigate()
    const [clinicalEpisode, setClinicalEpisode] = useState<ClinicalEpisode>()

    const [therapySessions, setTherapySessions] = useState<TherapySession[]>([])
    const [therapySessionsTotal, setTherapySessionsTotal] = useState(-1)
    const { pagination: therapySessionsPagination, onChangePagination: onChangeTherapySessionsPagination } = usePagination({ total: therapySessionsTotal })
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        (async function getClinicalEpisode() {

            if (clinicalEpisodeId) {
                setIsUpdateMode(true)

                const ce = await api.findBy(clinicalEpisodeId)

                setClinicalEpisode(ce)
            }

        })()
    }, [])

    const onSaveHandler = async (clinicalEpisode: CreateClinicalEpisode | UpdateClinicalEpisode, cb: () => void) => {
        if (isUpdateMode) {
            try {
                await api.update(clinicalEpisode as UpdateClinicalEpisode)
                cb()
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        } else {
            try {
                (clinicalEpisode as CreateClinicalEpisode).patientId = patientId as string

                await api.create(clinicalEpisode as CreateClinicalEpisode)
                cb()
                navigate(`/patients/${patientId}`)
            } catch (error: unknown) {
                console.log({ error })
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        }
    }

    const fetchTheraySessionsData = () => {
        if (!isUpdateMode) {
            return
        }

        (async function getTherapySessions() {
            const { data: ts, meta } = await tsApi.find({ pagination: { ...therapySessionsPagination }, sort: { date: 'DESC' } }, { clinicalEpisodeId })

            setTherapySessions(ts)
            setTherapySessionsTotal(meta.pagination.total)
        })()
    }

    // FETCH THERAPY SESSIONS DATA
    useEffect(() => {
        fetchTheraySessionsData()
    }, [therapySessionsPagination, clinicalEpisodeId, isUpdateMode])

    const addTherapySession = async (ts: CreateTherapySession) => {
        const t = {
            date: ts.date,
            description: ts.description,
            typeId: ts.type.id
        }
        // @ts-ignore
        const res = await tsApi.create(t, { clinicalEpisodeId })

        if (res) {
            setIsOpen(false)
            fetchTheraySessionsData()
        }
    }

    const deleteTherapySession = async (ts: TherapySession) => {
        const res = await tsApi.remove(ts.id, { clinicalEpisodeId })

        if (res) {
            fetchTheraySessionsData()
        }
    }

    const goToCreateWorkoutPlanPage = () => navigate(`/patients/${patientId}/workout-plan/new`, { state: { patientId, clinicalEpisode } })

    return <>
        <div className="container-fluid">
            <div className="row border-bottom py-3">
                <div className="col-12">
                    <ClinicalEpisodeForm
                        onSave={onSaveHandler}
                        clinicalEpisode={clinicalEpisode} />
                </div>
            </div>
            {
                isUpdateMode &&
                <div className="row py-3">
                    <div className="col-12 border-bottom">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4>DIARIO CLINICO</h4>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => setIsOpen(true)}>
                                +
                            </button>
                        </div>
                        <TherapySessionTable
                            therapySessions={therapySessions}
                            pagination={therapySessionsPagination}
                            onChangePagination={onChangeTherapySessionsPagination}
                            onDelete={deleteTherapySession}
                            total={therapySessionsTotal} />

                        <Modal
                            isOpen={isOpen}
                            onRequestClose={() => { setIsOpen(false) }}>
                            <TherapySessionForm
                                onSave={addTherapySession} />
                        </Modal>
                    </div>
                    <div className="col-12 py-3">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4>SCHEDE ESERCIZIO</h4>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={goToCreateWorkoutPlanPage}>+</button>
                        </div>
                        <WorkoutPlansWidget {...{ clinicalEpisodeId }} />
                    </div>
                </div>
            }
        </div>
    </>
};

export default ClinicalEpisodePage