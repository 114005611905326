import React, { ReactElement, FC, useMemo } from 'react'
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { OnChangePaginationFn } from '@/hooks/usePagination'
import TablePagination from '@/components/organisms/table/TablePagination'
import Table from '@/components/organisms/table/Table'
import { Pagination } from '@/services/api.model'
import { User } from '@/services/user/model'
import { Tenant } from '@/services/tenant/model'

type UserTableProps = {
    users: User[]
    total: number
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
    onEdit: (user: User) => Promise<void>
    onDelete: (user: User) => Promise<void>
}

const UserTable: FC<UserTableProps> = ({ users, total, pagination, onChangePagination, onEdit, onDelete }): ReactElement => {
    const columns = useMemo<ColumnDef<User>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                cell: d => <span title={d.cell.getValue() as string}>{(d.cell.getValue() as string).substring(0, 4)}...</span>
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorKey: 'role',
                header: 'Role'
            },
            {
                accessorKey: 'plan',
                header: 'Plan'
            },
            {
                accessorKey: 'status',
                header: 'Status'
            },
            {
                accessorKey: 'tenants',
                header: '# tenants',
                cell: d => (d.cell.getValue() as Tenant[])?.length
            },
            {
                accessorKey: 'createdAt',
                header: 'Register date',
                cell: d => (d.cell.getValue() as Date)?.toLocaleString()
            },
            {
                is: 'lastLogin',
                header: 'Last login',
                cell: d => d.row.original.lastLogin.date?.toLocaleString()
            },
            {
                is: 'lastLoginIP',
                header: 'Last login IP',
                cell: d => d.row.original.lastLogin.ip
            },
            {
                id: 'lastLoginUA',
                header: 'Last login UA',
                cell: d => d.row.original.lastLogin.userAgent
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    <button className="btn btn-primary btn-sm" onClick={onEdit.bind(this, props.row.original)}>Modifica</button>
                    {
                        'SUPER_ADMIN' !== props.row.original.role
                        && <button className="btn btn-danger btn-sm ms-1" onClick={onDelete.bind(this, props.row.original)}>Elimina</button>
                    }
                </>
            }
        ], [])

    const table = useReactTable<User>({
        data: users,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
    })

    return <>
        <Table<User> table={table} />
        <TablePagination<User> table={table} onChangePagination={onChangePagination} />
    </>
}

export default UserTable