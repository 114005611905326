import { APIClient } from '@services/api'
import { TherapySession, CreateTherapySession, UpdateTherapySession, TherapySessionResponse } from './model'
import { APIClientConfig } from '../../api.model'

class TherapySessionAPI extends APIClient<CreateTherapySession, TherapySession, UpdateTherapySession, unknown, unknown, TherapySessionResponse> {

    protected fromResponseData(data: TherapySessionResponse): TherapySession {
        return {
            id: data.id,
            clinicalEpisodeId: data.clinicalEpisodeId,
            date: new Date(data.date),
            description: data.description,
            type: {
                id: data.type.id,
                name: data.type.name
            }
        } as TherapySession
    }

    protected toPayload(resource: CreateTherapySession | TherapySession): string | Object {
        return JSON.parse(JSON.stringify(resource))
    }

}

const CONFIG: APIClientConfig = {
    path: '/clinical-episodes/:clinicalEpisodeId/therapy-sessions'
}

export const api = new TherapySessionAPI(CONFIG)