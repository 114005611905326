import React, { ReactElement, FC, useState, useEffect } from 'react'
import { DateTime } from 'luxon'

import { Calendar } from '@components/organisms/calendar/Calendar'
import { CalendarEvent } from '@components/organisms/calendar/event/CalendarEvent'
import { Appointment, AppointmentId, CreateAppointment, UpdateAppointment } from '@/services/calendar/appointment/model';
import Modal from '@/components/molecules/modal/Modal';
import useApi from '@/hooks/useApi';
import { api as appApi } from '@services/calendar/api'

type SelectedSlot = { start: DateTime, end: DateTime }

const CalendarPage: FC = (): ReactElement => {
    const [currentAppointment, setCurrentAppointment] = useState<SelectedSlot | Appointment>()
    const [isOpen, setIsOpen] = useState(false)

    const { run: fetchAppointments } = useApi({ callback: async () => await appApi.find({ filters: { status: { neq: 'cancelled' } } }) })
    const { run: createAppointment } = useApi({ callback: async (args) => await appApi.create(args[0] as CreateAppointment) })
    const { run: updateAppointment } = useApi({ callback: async (args) => await appApi.update(args[0] as UpdateAppointment) })
    const { run: deleteAppointment } = useApi({ callback: async (args) => await appApi.remove(args[0]) })

    const [appointments, setAppointments] = useState<Appointment[]>([])

    useEffect(() => {
        fetchAndSetAppointments()
    }, [])

    const onSaveHandler = async (data: CreateAppointment | UpdateAppointment) => {
        const isEdit = data && 'id' in data

        if (isEdit) {
            await updateAppointment(data)
        } else {
            await createAppointment(data)
        }

        await fetchAndSetAppointments()

        //close modals
        setIsOpen(false)
    }

    const onDeleteHandler = async ({ id }: AppointmentId) => {
        await deleteAppointment(id)
        await fetchAndSetAppointments()

        //close modals
        setIsOpen(false)
    }

    const fetchAndSetAppointments = async () => {
        const res = await fetchAppointments()
        setAppointments(res.data)
    }

    const handleSelectSlot = (e: { start: DateTime, end: DateTime }) => {
        setIsOpen(true)
        setCurrentAppointment(e)
    }

    const handleSelectAppointment = (a: Appointment) => {
        setIsOpen(true)
        setCurrentAppointment(a)
    }

    return <>
        <div className="container-fluid">
            <div className="row py-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <Calendar
                                events={appointments}
                                onSelectSlot={handleSelectSlot}
                                onSelectAppointment={handleSelectAppointment}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            isOpen={isOpen}
            onRequestClose={() => { setIsOpen(false) }}>
            <CalendarEvent
                onSave={onSaveHandler}
                onDelete={onDeleteHandler}
                event={currentAppointment} />
        </Modal>
    </>
};

export default CalendarPage