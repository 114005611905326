import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom"

import useApi from "@hooks/useApi"
import { api as ceApi } from "@services/clinical-episode/api"
import { ClinicalEpisode } from "@services/clinical-episode/model"
import { api as wpApi } from "@services/workout-plan/api"
import { CreateWorkoutPlan, UpdateWorkoutPlan, WorkoutPlan as WorkoutPlanModel } from "@services/workout-plan/model"
import AsyncWrapper from "@components/atoms/AsyncWrapper"

import WorkoutPlanForm from "./WorkoutPlanForm"

const WorkoutPlan: FC = () => {
    const navigate = useNavigate()
    const { id: patientId, workoutPlanId } = useParams()
    const routeLoaderDate = useRouteLoaderData('workout-plan-index')
    const { state } = useLocation()
    const [clinicalEpisode, setClinicalEpisode] = useState<ClinicalEpisode>()
    const [workoutPlan, setWorkoutPlan] = useState<WorkoutPlanModel>()
    const { run: fetchClinicalEpisode, ...fetchClinicalEpisodeStatus } = useApi({ callback: async (id: string) => await ceApi.findBy(id) })
    const { run: createWorkoutPlan, ...createWorkoutPlanStatus } = useApi({ callback: async (args) => await wpApi.create(args[0] as CreateWorkoutPlan) })
    const { run: updateWorkoutPlan, ...updateWorkoutPlanStatus } = useApi({ callback: async (args) => await wpApi.update(args[0] as UpdateWorkoutPlan) })
    const { run: fetchWorkoutPlan, ...fetchWorkoutPlanStatus } = useApi({ callback: async (args) => await wpApi.findBy(args[0] as string) })

    const isEdit = !!workoutPlanId

    useEffect(() => {
        (async () => {
            if (state && state.clinicalEpisode) {
                setClinicalEpisode(state.clinicalEpisode)
            } else {
                const { workoutPlan } = routeLoaderDate as { workoutPlan: WorkoutPlanModel }

                setWorkoutPlan(workoutPlan)

                const ce = await fetchClinicalEpisode(workoutPlan.clinicalEpisodeId)
                ce && setClinicalEpisode(ce)
            }
        })()
    }, [])

    const onSaveHandler = async (wp: CreateWorkoutPlan | UpdateWorkoutPlan) => {
        if (isEdit) {
            await updateWorkoutPlan(wp)

            const updatedWorkoutPlan: WorkoutPlanModel = await fetchWorkoutPlan(workoutPlanId)

            setWorkoutPlan(updatedWorkoutPlan)

        } else {
            const newId = await createWorkoutPlan(wp)

            navigate(`/patients/${patientId}/workout-plan/${newId}`)
        }
    }

    return <>
        <AsyncWrapper {...fetchClinicalEpisodeStatus}>
            <div className="h3">
                {isEdit ? `Modifica` : `Nuova`} scheda per il caso clinico: <strong>{clinicalEpisode?.name}</strong>
            </div>
            <AsyncWrapper {...createWorkoutPlanStatus}>
                <AsyncWrapper {...updateWorkoutPlanStatus}>
                    <AsyncWrapper {...fetchWorkoutPlanStatus}>
                        <WorkoutPlanForm
                            workoutPlan={workoutPlan}
                            clinicalEpisodeId={workoutPlan?.clinicalEpisodeId || clinicalEpisode?.id}
                            onSave={onSaveHandler} />
                    </AsyncWrapper>
                </AsyncWrapper>
            </AsyncWrapper>
        </AsyncWrapper>
    </>
}

export default WorkoutPlan