import './Modal.scss'

import { FC, PropsWithChildren, ReactElement } from "react"

import LibModal from "react-modal"

LibModal.setAppElement('#root')

type ModalProps = {
    isOpen: boolean
    onRequestClose: () => void
}

const style: LibModal.Styles = {
    overlay: {
        zIndex: 999
    },
    content: {
        maxWidth: '600px',
        height: 'fit-content',
        margin: 'auto'
    }
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ children, isOpen, onRequestClose }): ReactElement => {
    return <>
        <LibModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={style}
        >
            {children}
        </LibModal>
    </>
}

export default Modal