import { ReactElement, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

import useApi from '@/hooks/useApi'
import { api as appointmentApi } from '@services/calendar/api'
import { Appointment } from '@/services/calendar/appointment/model'
import { ReactComponent as PublicDiscussionSVG } from "@assets/public-discussion.svg"
import { ReactComponent as SelectionSVG } from "@assets/selection.svg"
import { AppointmentsList } from '@/components/molecules/appointments-list'

const DashboardPage: FC = (): ReactElement => {
    const { run: fetchAppointments } = useApi({
        callback: async () => await appointmentApi.find({
            filters: {
                start: { gte: DateTime.now().toISODate() },
                status: { neq: 'cancelled' }
            },
            sort: {
                start: 'ASC',
                end: 'ASC'
            },
            pagination: {
                page: 0,
                perPage: 100
            }
        })
    })
    const [nextAppointment, setNextAppointment] = useState<Appointment[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const res = await fetchAppointments()

            setNextAppointment(res.data)
        })()
    }, [])

    const nextEventClickHandler = (appointment: Appointment) => {
        navigate(`/patients/${appointment.patientId}`)
    }

    return <>
        <div className="container-fluid">
            <div className="row g-3">
                <div className="col-12 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">I tuoi prossimi appuntamenti</h4>
                            {nextAppointment && <AppointmentsList list={nextAppointment} onClick={nextEventClickHandler} />}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Schede in scadenza</h4>
                            <SelectionSVG />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Messaggi</h4>
                            <PublicDiscussionSVG />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default DashboardPage