import { getJwtState } from '@/state'
import { APIClient, client } from '../api'
import { User, UpdateUser, UserResponse, UpdateMe } from './model'
import { Tenant } from '../tenant/model'
import { APIClientConfig } from '../api.model'

class UserAPI extends APIClient<unknown, User, any, unknown, unknown, UserResponse> {

    public async me() {
        const { data } = await client.get(`/users/me`)

        return this.fromResponseData(data)
    }

    public async updateMe(u: UpdateMe) {
        const { data } = await client.patch<UserResponse>(`/users/me`, this.toPayload(u))

        return this.fromResponseData(data)
    }

    public async deleteTenant(tenant: Tenant) {
        const { decoded } = getJwtState()

        const { status } = await client.delete(`/users/${decoded.id}/tenants/${tenant.id}`)

        return status === 204
    }

    protected fromResponseData(data: UserResponse): User {
        return {
            id: data.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            language: data.language,
            plan: data.plan,
            role: data.role,
            status: data.status,
            tenants: data.tenants.map((t: any) => ({ id: t.id, role: t.role, name: t.name, hostname: t.hostname } as Tenant)),
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
            lastLogin: {
                ...(data.lastLogin && { date: new Date(data.lastLogin) }),
                ...(data.lastLoginIP && { ip: data.lastLoginIP }),
                ...(data.lastLoginUA && { userAgent: data.lastLoginUA }),
            }
        }
    }

    protected toPayload(u: User | UpdateUser | UpdateMe): string | Object {
        return {
            id: u.id,
            ...('plan' in u && { plan: u.plan }),
            ...('role' in u && { role: u.role }),
            ...('status' in u && { status: u.status }),
            ...('firstName' in u && { firstName: u.firstName }),
            ...('lastName' in u && { lastName: u.lastName }),
            ...('language' in u && { language: u.language }),
        } as User | UpdateUser
    }

}

const CONFIG: APIClientConfig = {
    path: 'users'
}

export const api = new UserAPI(CONFIG)