import { APIClient } from '@services/api'
import { APIClientConfig } from '../api.model'
import { Appointment, AppointmentId, AppointmentResponse, CreateAppointment, UpdateAppointment } from './appointment/model'
import { DateTime } from 'luxon'

class AppointmentAPI extends APIClient<CreateAppointment, Appointment, UpdateAppointment, AppointmentId, unknown, AppointmentResponse> {

    protected fromResponseData(data: AppointmentResponse): Appointment {
        return {
            id: data.id,
            patientId: data.patientId,
            userId: data.userId,
            title: data.title,
            status: data.status,
            start: DateTime.fromISO(data.start),
            end: DateTime.fromISO(data.end),
            ...(data.description && { description: data.description }),
        } as Appointment
    }

    protected toPayload(resource: CreateAppointment | Appointment): string | Object {
        return {
            ...JSON.parse(JSON.stringify(resource)),
            start: resource.start.toISO(),
            end: resource.end.toISO()
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/appointments'
}

export const api = new AppointmentAPI(CONFIG)