import { FC, useState } from "react"
import { useLoaderData } from "react-router-dom"

import Modal from "@components/molecules/modal/Modal"
import { PublicWorkoutPlan } from "@services/workout-plan/model"
import { Exercise } from "./Exercise"

const WorkoutPlan: FC = () => {
    const { workoutPlan } = useLoaderData() as { workoutPlan: PublicWorkoutPlan }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalText, setModalText] = useState('')

    const showHandler = (text: string) => {
        setModalText(text)
        setIsModalOpen(true)
    }

    return <div className="container">
        <div className="mt-3 p-3 text-center rounded border">
            <div className="h6">La tua scheda</div>
            <h1 className="mb-0">{workoutPlan.name}</h1>
            <div className="fw-light fst-italic">{workoutPlan.validity.startDate.toLocaleString()} - {workoutPlan.validity.endDate.toLocaleString()}</div>
        </div>

        {workoutPlan.notes && <div className="mt-3 pt-3 text-center">
            <div className="h4">Indicazioni dal fisioterapista</div>
            <p dangerouslySetInnerHTML={{ __html: workoutPlan.notes.replaceAll('\n', '<br />') }} />
        </div>}

        <div className="row gx-5 gy-3 my-3">
            {
                workoutPlan
                    .exercises
                    .map(e => <div key={e.id} className="col-12 col-md-4">
                        <Exercise
                            exercise={e}
                            onShowDescription={showHandler}
                        />
                    </div>)
            }
        </div>
        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
            {modalText}
        </Modal>
    </div>
}

export default WorkoutPlan