import { FC } from "react"

import './Checkbox.scss'

type CheckboxProps = {
    label?: string
    id: string
}

const Checkbox: FC<CheckboxProps> = ({ id, label }) => {
    return <div className="hu-checkbox">
        <input type="checkbox" {...{ id }} />
    </div>
}

export { Checkbox }