import { Row, Table as TanStackTable, flexRender } from "@tanstack/react-table"

type CustomTableMeta<T> = {
    options?: {
        meta?: {
            getRowClass?: (row: Row<T>) => string
        }
    }
}

type TableProps<T> = {
    table: TanStackTable<T> & CustomTableMeta<T>
}

function Table<T extends any>({ table }: TableProps<T>) {
    return (
        <table className="table table-hover align-middle">
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} colSpan={header.colSpan} scope='col'>
                                {header.isPlaceholder ? null : (
                                    <div>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </div>
                                )}
                            </th>
                        )
                        )}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}
                        {...{ ...(table.options.meta?.getRowClass && { className: table.options.meta?.getRowClass(row) }) }}
                    >
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className='border border-light-subtle'>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Table