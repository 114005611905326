import { FC } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import { DateTime } from "luxon"

import { WorkoutPlan, CreateWorkoutPlan, UpdateWorkoutPlan } from "@services/workout-plan/model"
import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { SelectWeekdays } from "@components/molecules/select-weekdays/SelectWeekDays"

import { CreateWorkoutPlanExercise, UpdateWorkoutPlanExercise, WorkoutPlanExercise } from "@services/workout-plan/workout-plan-exercise/model"
import WorkoutPlanExerciseForm from "./WorkoutPlanExerciseForm"

const DEFAULT_TIME = DateTime.now().set({ hour: 18, minute: 0, second: 0, millisecond: 0 })

type WorkoutPlanFormPros = {
    workoutPlan?: WorkoutPlan
    clinicalEpisodeId?: string
    onSave: (wp: CreateWorkoutPlan | UpdateWorkoutPlan) => void,
}

type ExercisesInputFormData = Array<{
    id?: string
    exerciseId: string
    sets: number
    reps: number
    rest: number
    days?: number[]
    notes?: string
}>

export type InputFormData = {
    name: string
    validity: [Date, Date]
    notes?: string
    notificationsEnabled: boolean
    exercises: ExercisesInputFormData
    days: number[]
    timeOfDay: Date
}

const WorkoutPlanForm: FC<WorkoutPlanFormPros> = ({ onSave, workoutPlan, clinicalEpisodeId }) => {
    const defaultFormValues: InputFormData | undefined = workoutPlan && toForm(workoutPlan)
    const methods = useForm<InputFormData>(defaultFormValues && { defaultValues: defaultFormValues })
    const {
        register,
        handleSubmit,
        control,
        formState: { isValid, isDirty }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    //useEffect(() => reset(toForm(workoutPlan)), [workoutPlan])

    const onSubmit: SubmitHandler<InputFormData> = async (data) => {
        const exercises: (CreateWorkoutPlanExercise | UpdateWorkoutPlanExercise)[] = data.exercises.map(e => ({
            ...(e.id && { id: e.id }),
            ...(e.exerciseId && { exerciseId: e.exerciseId }),
            ...(e.sets && { sets: e.sets }),
            ...(e.reps && { reps: e.reps }),
            ...(e.rest && { rest: e.rest }),
            ...(e.notes && { notes: e.notes }),
            days: (e.days && Object.fromEntries(["1", "2", "3", "4", "5", "6", "7"].map(d => [d, e.days?.includes(parseInt(d))]))) || []
        } as CreateWorkoutPlanExercise | UpdateWorkoutPlanExercise))

        const wp: CreateWorkoutPlan | UpdateWorkoutPlan = {
            ...(workoutPlan && workoutPlan.id && { id: workoutPlan.id }),
            clinicalEpisodeId,
            name: data.name,
            validity: {
                startDate: DateTime.fromJSDate(data.validity[0]),
                endDate: DateTime.fromJSDate(data.validity[1]),
            },
            notes: data.notes,
            notificationsEnabled: data.notificationsEnabled,
            days: (data.days && Object.fromEntries(["1", "2", "3", "4", "5", "6", "7"].map(d => [d, data.days?.includes(parseInt(d))]))) || [],
            timeOfDay: DateTime.fromJSDate(data.timeOfDay),
            exercises,
        } as CreateWorkoutPlan | UpdateWorkoutPlan

        onSave(wp)
    }

    const isEditMode = !!workoutPlan

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col-12 col-md-6">
                    <FormControlWrapper
                        label='Nome scheda'
                        name={getFieldName('name')}
                        {...makeCommonFormControlWrapperAttributes('name')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('name')}
                            {...register('name', { required: true })}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12 col-md-6">
                    <FormControlWrapper
                        label='Validità'
                        name={getFieldName('validity')}
                        {...makeCommonFormControlWrapperAttributes('validity')}>
                        <Controller
                            control={control}
                            name={getFieldName('validity') as any}
                            defaultValue={[new Date(), undefined]}
                            rules={{
                                required: true,
                                validate: (validity: [Date | undefined, Date | undefined]) => {
                                    return !!(validity[0] && validity[1])
                                }
                            }}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <DatePicker
                                    selectsRange
                                    selected={value[0]}
                                    startDate={value[0]}
                                    endDate={value[1]}
                                    onChange={(interval: [startDate: Date, endDate: Date]) => onChange(interval)} // send value to hook form
                                    onBlur={onBlur}
                                    dateFormat="dd/MM/yyyy"
                                    withPortal
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                    className='form-control'
                                    {...makeCommonFormControlAttributes('validity')}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12 border border-2 rounded py-2 px-0">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-12 col-lg-4">
                                <FormControlWrapper
                                    label='Giorni'
                                    name={getFieldName('days')}
                                    {...makeCommonFormControlWrapperAttributes('days')}>
                                    <Controller
                                        name={getFieldName('days')}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                            <SelectWeekdays field={'days'} required={true} {...{ onChange, onBlur, value }} />
                                        }
                                    />
                                </FormControlWrapper>
                            </div>

                            <div className="col-12 col-md-auto">
                                <FormControlWrapper
                                    label='Orario'
                                    name={getFieldName('timeOfDay')}
                                    {...makeCommonFormControlWrapperAttributes('timeOfDay')}>
                                    <Controller
                                        control={control}
                                        name={getFieldName('timeOfDay') as any}
                                        defaultValue={DEFAULT_TIME.toJSDate()}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                            <DatePicker
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                timeFormat="HH:mm"
                                                dateFormat="HH:mm"
                                                selected={value}
                                                onChange={time => onChange(time)}
                                                onBlur={onBlur}
                                                withPortal
                                                required
                                                className='form-control'
                                                {...makeCommonFormControlAttributes('timeOfDay')}
                                            />
                                        }
                                    />
                                </FormControlWrapper>
                            </div>

                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="notificationsEnabledCheckbox"
                                        {...register("notificationsEnabled")} />
                                    <label className="form-check-label" htmlFor="notificationsEnabledCheckbox">
                                        Attiva le notifiche
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 border border-2 rounded py-2">
                    <WorkoutPlanExerciseForm {...{ control, register }} />
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Note per il paziente'
                        name={getFieldName('notes')}
                        {...makeCommonFormControlWrapperAttributes('notes')}>
                        <textarea
                            rows={10}
                            className="form-control"
                            {...makeCommonFormControlAttributes('notes')}
                            {...register('notes')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        {isEditMode ? 'Salva' : 'Crea'}
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default WorkoutPlanForm

const toForm = (wp?: WorkoutPlan): InputFormData | undefined => {
    return wp && {
        name: wp.name,
        validity: [wp.validity.startDate.toJSDate(), wp.validity.endDate.toJSDate()],
        notes: wp.notes,
        notificationsEnabled: wp.notificationsEnabled,
        exercises: toExercisesForm(wp.exercises),
        days: Object
            .keys(wp.days)
            //@ts-ignore
            .filter(d => wp.days[d] === true)
            .map(d => parseInt(d)),
        timeOfDay: wp.timeOfDay.toJSDate()
    }
}

const toExercisesForm = (exercises: Array<WorkoutPlanExercise>): ExercisesInputFormData => {
    return exercises.map(e => ({
        id: e.id,
        exerciseId: e.exerciseId,
        sets: e.sets,
        reps: e.reps,
        rest: e.rest,
        days: Object
            .keys(e.days)
            //@ts-ignore
            .filter(d => e.days[d] === true)
            .map(d => parseInt(d)),
        ...(e.notes && { notes: e.notes })
    }))
}