import { APIClient } from '@services/api'
import { CreatePatient, Patient, PatientResponse, UpdatePatient } from './model'
import { APIClientConfig } from '../api.model'

class PatientAPI extends APIClient<CreatePatient, Patient, UpdatePatient, unknown, unknown, PatientResponse> {

    protected fromResponseData(data: PatientResponse): Patient {
        return {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            ...(data.email && { email: data.email }),
            ...(data.phone && { phone: data.phone }),
            ...(data.birthdate && { birthdate: new Date(data.birthdate) }),
            ...(data.addresses && { addresses: data.addresses }),
            ...(data.addresses && {
                addresses: {
                    ...(data.addresses.residence && {
                        residence: {
                            ...(data.addresses.residence.country && { country: { iso: data.addresses.residence.country.iso } }),
                            ...(data.addresses.residence.state && { state: { iso: data.addresses.residence.state.iso } }),
                            ...(data.addresses.residence.city && { city: { id: parseInt(data.addresses.residence.city.id) } }),
                            ...(data.addresses.residence.address && { address: data.addresses.residence.address }),
                            ...(data.addresses.residence.zip && { zip: data.addresses.residence.zip }),
                        }
                    }),
                    ...(data.addresses.tax && {
                        tax: {
                            ...(data.addresses.tax.country && { country: { iso: data.addresses.tax.country.iso } }),
                            ...(data.addresses.tax.state && { state: { iso: data.addresses.tax.state.iso } }),
                            ...(data.addresses.tax.city && { city: { id: parseInt(data.addresses.tax.city.id) } }),
                            ...(data.addresses.tax.address && { address: data.addresses.tax.address }),
                            ...(data.addresses.tax.zip && { zip: data.addresses.tax.zip }),
                        }
                    })
                }
            }),
            ...(data.TIN && { TIN: data.TIN }),
            ...(data.VAT && { VAT: data.VAT }),
            ...(data.sports && { sports: data.sports }),
            ...(data.jobs && { jobs: data.jobs }),
        } as Patient
    }

    protected toPayload(resource: CreatePatient | Patient): string | Object {
        return JSON.parse(JSON.stringify(resource))
    }

}

const CONFIG: APIClientConfig = {
    path: '/patients'
}

export const api = new PatientAPI(CONFIG)