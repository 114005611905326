import { client } from '@services/api'
import { CreateTerm, Term, TermPayload, TermResponse, UpdateTerm } from './model'
import { FindParams, ResponseMeta } from '../api.model'
import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_PER_PAGE } from '../constants'

type TaxonomyAPIFactoryArgs = {
    taxonomyId: string
}

const BASE_URL = '/taxonomies/:taxonomyId'

export type TaxonomyAPIFactoryType = {
    findTerms: (params?: FindParams<Term>) => Promise<{ data: Term[], meta: ResponseMeta }>
    findTermById: (termId: number) => Promise<Term | undefined>
    createTerm: (term: CreateTerm) => Promise<boolean>
    updateTerm: (term: UpdateTerm) => Promise<boolean>
    removeTerm: (termId: number) => Promise<boolean>
}

function taxonomyAPIFactory({ taxonomyId }: TaxonomyAPIFactoryArgs): TaxonomyAPIFactoryType {

    const baseUrl = BASE_URL.replace(':taxonomyId', taxonomyId)

    const findTerms = async (params?: FindParams<Term>): Promise<{ data: Term[], meta: ResponseMeta }> => {
        const mergedParams: FindParams<Term> = {
            pagination: {
                page: DEFAULT_PAGINATION_PAGE,
                perPage: DEFAULT_PAGINATION_PER_PAGE,
                withCount: true,
                ...(params && params.pagination)
            },
            ...(params && params.sort && { sort: params.sort }),
            ...(params && params.filters && { filters: params.filters })
        }

        const { data } = await client.get<{ data: TermResponse[], meta: ResponseMeta }>(baseUrl, { params: mergedParams })

        const terms = (data.data && data.data instanceof Array && data.data.map(t => fromResponse(t))) || []

        return {
            data: terms,
            meta: data.meta
        }
    }

    const findTermById = async (termId: number): Promise<Term | undefined> => {
        const { data } = await client.get<TermResponse>(`${baseUrl}/${termId}`)

        return fromResponse(data)
    }

    const createTerm = async (term: CreateTerm): Promise<boolean> => {
        const { status } = await client.post<boolean>(`${baseUrl}`, toPayload(term))

        return status === 201
    }

    const updateTerm = async (term: UpdateTerm): Promise<boolean> => {
        const { status } = await client.patch<TermResponse>(`${baseUrl}/${term.id}`, toPayload(term))

        return status === 200
    }

    const removeTerm = async (termId: number): Promise<boolean> => {
        const { status } = await client.delete(`${baseUrl}/${termId}`)

        return status === 204
    }

    const toPayload = (term: Term | CreateTerm | UpdateTerm): TermPayload => {
        return JSON.parse(JSON.stringify(term))
    }

    const fromResponse = (t: TermResponse): Term => {
        return {
            id: parseInt(t.id),
            name: t.name
        }
    }

    return {
        findTerms,
        findTermById,
        createTerm,
        updateTerm,
        removeTerm
    }

}

export default taxonomyAPIFactory