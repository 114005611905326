import { APIClient } from '@services/api'
import { Exercise, CreateExercise, UpdateExercise, ExerciseResponse } from './exercise.model'
import { APIClientConfig } from '../api.model'

class ExerciseAPI extends APIClient<CreateExercise, Exercise, UpdateExercise, unknown, unknown, ExerciseResponse> {

    protected fromResponseData(data: ExerciseResponse): Exercise {
        return {
            id: data.id,
            name: data.name,
            ...(data.description && { description: data.description }),
            status: data.status,
            slug: data.slug,
            ...(data.diseases && { diseases: data.diseases.map(d => ({ id: parseInt(d.id), name: d.name })) }),
            ...(data.equipments && { equipments: data.equipments.map(d => ({ id: parseInt(d.id), name: d.name })) }),
            ...(data.joints && { joints: data.joints.map(d => ({ id: parseInt(d.id), name: d.name })) }),
            ...(data.movements && { movements: data.movements.map(d => ({ id: parseInt(d.id), name: d.name })) }),
            ...(data.muscles && { muscles: data.muscles.map(d => ({ id: parseInt(d.id), name: d.name })) }),
            ...(data.therapeuticGoals && { therapeuticGoals: data.therapeuticGoals.map(d => ({ id: parseInt(d.id), name: d.name })) }),

        } as Exercise
    }

    protected toPayload(resource: CreateExercise | Exercise): Object {
        return {
            name: resource.name,
            ...(resource.status && { status: resource.status }),
            ...(resource.slug && { slug: resource.slug }),
            ...(resource.description && { description: resource.description }),
            ...(resource.diseases && { diseases: resource.diseases.map(v => v.id) }),
            ...(resource.equipments && { equipments: resource.equipments.map(v => v.id) }),
            ...(resource.joints && { joints: resource.joints.map(v => v.id) }),
            ...(resource.movements && { movements: resource.movements.map(v => v.id) }),
            ...(resource.muscles && { muscles: resource.muscles.map(v => v.id) }),
            ...(resource.therapeuticGoals && { therapeuticGoals: resource.therapeuticGoals.map(v => v.id) }),
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/exercises'
}

export const api = new ExerciseAPI(CONFIG)