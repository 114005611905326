import { atomWithStorage, createJSONStorage } from 'jotai/utils'

import { User } from '@services/user/model'

// JWT
type jwtStore = {
    token: string | null, decoded: any | null
}

const JWT_STORAGE_KEY = 'jwt'

export const jwtStateStore = createJSONStorage<any>(() => localStorage)

export const jwtState = atomWithStorage<jwtStore | null>(JWT_STORAGE_KEY, null, jwtStateStore)

export function setJwtState(jwt: jwtStore | null) {
    if (jwt === null) {
        jwtStateStore.removeItem(JWT_STORAGE_KEY)
        return
    }

    jwtStateStore.setItem(JWT_STORAGE_KEY, jwt)
}

export function getJwtState(): jwtStore {
    return jwtStateStore.getItem(JWT_STORAGE_KEY, null)
}

// Current user STORE
export type currentUserStore = {
    user: User,
    tenantId: string | null
}

const CURRENT_USER_STORAGE_KEY = 'currentUser'

export const currentUserStateStorage = createJSONStorage<any>(() => localStorage)

export const currentUserState = atomWithStorage<currentUserStore | null>(CURRENT_USER_STORAGE_KEY, null, currentUserStateStorage)

export function setCurrentUserState(currentUser: currentUserStore | null) {
    if (currentUser === null) {
        currentUserStateStorage.removeItem(CURRENT_USER_STORAGE_KEY)
        return
    }

    currentUserStateStorage.setItem(CURRENT_USER_STORAGE_KEY, currentUser)
}

export function getCurrentUserState(): currentUserStore | null {
    return currentUserStateStorage.getItem(CURRENT_USER_STORAGE_KEY, null)
}