import { FC, useEffect, useState } from "react"
import { Control, Controller, UseFormRegister, useFieldArray } from "react-hook-form"
import Select, { SingleValue } from 'react-select'

import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { SelectWeekdays } from "@components/molecules/select-weekdays/SelectWeekDays"

import { api as exApi } from '@services/exercise/exercise.api'
import { Exercise } from "@services/exercise/exercise.model"
import { InputFormData } from "./WorkoutPlanForm"

const SELECT_EXERCISE_PH = 'Seleziona esercizio'

type WorkoutPlanExerciseFormProps = {
    control: Control<InputFormData>
    register: UseFormRegister<InputFormData>
}

const WorkoutPlanExerciseForm: FC<WorkoutPlanExerciseFormProps> = ({ control, register }) => {
    const [exerciseList, setExerciseList] = useState<Exercise[]>([])

    useEffect(() => {
        fetchExercises()

        async function fetchExercises() {
            const { data } = await exApi.find({ filters: { status: { eq: 'publish' } }, pagination: { perPage: -1 } }) // Fetch all exercises => TODO: make async calls
            setExerciseList(data)
        }
    }, [])

    const { fields, append, remove } = useFieldArray<InputFormData>({
        name: "exercises",
        control
    })

    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const removeExercise = (index: number) => {
        remove(index)
    }
    const addExercise = () => {
        append({
            exerciseId: '',
            sets: 3,
            reps: 10,
            rest: 30,
            days: [],
            notes: ''
        })
    }

    return <div className="row g-3 my-0">
        {
            fields.map((field, idx) => <div key={field.id}
                className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body">

                        <div className="row g-3">
                            <div className="col-12">
                                <Controller
                                    name={getFieldName(`exercises.${idx}.exerciseId`)}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                        <Select
                                            options={getExercisesOptions(exerciseList)}
                                            value={getExerciseOption(exerciseList, value)}
                                            onChange={onExerciseSelectChangeFactory(onChange)}
                                            onBlur={onBlur}
                                            isClearable
                                            required
                                            placeholder={SELECT_EXERCISE_PH}
                                            {...makeCommonFormControlAttributes(`exercises.${idx}.exerciseId`)}
                                        />
                                    }
                                />
                            </div>

                            <div className="col-12">
                                <Controller
                                    name={getFieldName(`exercises.${idx}.days`)}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                        <SelectWeekdays field={`exercises.${idx}.days`} {...{ onChange, onBlur, value }} />
                                    }
                                />
                            </div>

                            <div className="col-md-4">
                                <FormControlWrapper
                                    label='Serie'
                                    name={getFieldName(`exercises.${idx}.sets`)}
                                    {...makeCommonFormControlWrapperAttributes(`exercises.${idx}.sets`)}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        {...makeCommonFormControlAttributes(`exercises.${idx}.sets`)}
                                        {...register(`exercises.${idx}.sets`, { required: true, valueAsNumber: true })}
                                    />
                                </FormControlWrapper>
                            </div>

                            <div className="col-md-4">
                                <FormControlWrapper
                                    label='Ripetizioni'
                                    name={getFieldName(`exercises.${idx}.reps`)}
                                    {...makeCommonFormControlWrapperAttributes(`exercises.${idx}.reps`)}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        {...makeCommonFormControlAttributes(`exercises.${idx}.reps`)}
                                        {...register(`exercises.${idx}.reps`, { required: true, valueAsNumber: true })}
                                    />
                                </FormControlWrapper>
                            </div>

                            <div className="col-md-4">
                                <FormControlWrapper
                                    label='Riposo (sec)'
                                    name={getFieldName(`exercises.${idx}.rest`)}
                                    {...makeCommonFormControlWrapperAttributes(`exercises.${idx}.rest`)}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        {...makeCommonFormControlAttributes(`exercises.${idx}.rest`)}
                                        {...register(`exercises.${idx}.rest`, { required: true, valueAsNumber: true })}
                                    />
                                </FormControlWrapper>
                            </div>

                            <div className="col-12">
                                <FormControlWrapper
                                    label='Note per il paziente'
                                    name={getFieldName(`exercises.${idx}.notes`)}
                                    {...makeCommonFormControlWrapperAttributes(`exercises.${idx}.notes`)}>
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        {...makeCommonFormControlAttributes(`exercises.${idx}.notes`)}
                                        {...register(`exercises.${idx}.notes`)}
                                    />
                                </FormControlWrapper>
                            </div>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm btn-danger w-100"
                            onClick={removeExercise.bind(this, idx)}>
                            Rimuovi
                        </button>
                    </div>
                </div>
            </div>)
        }

        <div className={`col-12 ${fields.length > 0 ? 'col-md-4' : 'mt-0 py-5'} d-flex flex-column align-items-center justify-content-center`}>
            <button
                type="button"
                className="btn btn-primary"
                onClick={addExercise}>
                Aggiungi esercizio
            </button>
        </div>
    </div>
}

export default WorkoutPlanExerciseForm

const getExercisesOptions = (exercises: Exercise[]) => {
    return exercises.map(e => ({ value: e.id, label: e.name }))
}

function getExerciseOption(exercises: Exercise[], exerciseId?: string) {
    const exercise = exercises.find(e => e.id === exerciseId)

    return (exerciseId
        && exercise
        && {
        value: exerciseId,
        label: exercise.name
    }) as { value: string, label: string } | undefined
}

function onExerciseSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: string, label: string }>) => {
        onChange(option?.value)
    }
}