import { APIClient } from '@services/api'
import { ClinicalEpisode, CreateClinicalEpisode, UpdateClinicalEpisode, ClinicalEpisodeResponse } from './model'
import { APIClientConfig } from '../api.model'

class ClinicalEpisodeAPI extends APIClient<CreateClinicalEpisode, ClinicalEpisode, UpdateClinicalEpisode, unknown, unknown, ClinicalEpisodeResponse> {

    protected fromResponseData(data: ClinicalEpisodeResponse): ClinicalEpisode {
        return {
            id: data.id,
            patientId: data.patientId,
            name: data.name,
            status: data.status,
            ...(data.medicalHistory && { medicalHistory: data.medicalHistory }),
            ...(data.drugs && { drugs: data.drugs }),
            ...(data.notes && { notes: data.notes }),
            ...(data.createdAt && { createdAt: new Date(data.createdAt) }),
            ...(data.updatedAt && { updatedAt: new Date(data.updatedAt) }),
        } as ClinicalEpisode
    }

    protected toPayload(resource: CreateClinicalEpisode | ClinicalEpisode): string | Object {
        return JSON.parse(JSON.stringify(resource))
    }

}

const CONFIG: APIClientConfig = {
    path: '/clinical-episodes'
}

export const api = new ClinicalEpisodeAPI(CONFIG)