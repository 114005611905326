import React, { ReactElement, FC, useMemo } from 'react'
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Pagination } from '@services/api.model'
import { TherapySession } from '@services/clinical-episode/therapy-session/model'
import { OnChangePaginationFn } from '@hooks/usePagination'
import TablePagination from '@components/organisms/table/TablePagination'
import Table from '@components/organisms/table/Table'
import { TypeClassMapper } from './constant'

type TherapySessionProps = {
    therapySessions: TherapySession[]
    total: number
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
    onDelete: (ts: TherapySession) => void
}

const TherapySessionTable: FC<TherapySessionProps> = ({ therapySessions, total, pagination, onChangePagination, onDelete }): ReactElement => {
    const columns = useMemo<ColumnDef<TherapySession>[]>(
        () => [
            {
                accessorKey: 'date',
                header: 'Data',
                cell: d => (d.cell.getValue() as Date)?.toLocaleDateString()
            },
            {
                accessorKey: 'description',
                header: 'Descrizione',
                cell: d => {
                    const description = d.cell.getValue() as string

                    return <div dangerouslySetInnerHTML={{ __html: description }} style={{ whiteSpace: "pre-wrap" }} />
                }
            },
            {
                accessorKey: 'type',
                header: 'Tipologia',
                cell: d => {
                    const type = d.cell.getValue() as { id: number, name: string }

                    return <span className={`badge ms-2 ${TypeClassMapper[type.id]}`}>
                        {type.name}
                    </span>
                }
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    <button className="btn btn-danger btn-sm ms-1" onClick={() => onDelete(props.row.original)}>Elimina</button>
                </>
            }

        ], [])

    const table = useReactTable<TherapySession>({
        data: therapySessions,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
    })

    return <>
        <Table<TherapySession> table={table} />
        <TablePagination<TherapySession> table={table} onChangePagination={onChangePagination} />
    </>
}

export default TherapySessionTable