import { useState, useEffect } from "react"

import { Country } from "@services/country/model"
import { State } from "@services/state/model"
import { api } from "@services/state/api"

const useStates = ({ initValue, country }: { initValue?: State[], country: Country }) => {
    const [states, setStates] = useState<State[]>(initValue || [])

    useEffect(() => {
        // LOAD STATES OF SELECTED COUNTRY
        if (country) {
            load(country)
        }

        async function load(country: Country) {
            const { data } = await api.find(
                {
                    pagination: { perPage: 999999 },
                    sort: { name: 'ASC' }
                },
                {
                    countryISO: country.iso
                }
            )

            setStates(data)
        }

    }, [country])

    return { states }
}

export default useStates