import { useMemo, useState } from "react"

import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_PER_PAGE } from "@/services/constants"
import { Pagination } from "@/services/api.model"

export type OnChangePaginationFn = (action: PaginationAction, value?: any) => void

export type PaginationAction = 'first' | 'last' | 'prev' | 'next' | 'goto'

type PaginationHookProps = {
    init?: Pagination
    total?: number
}

const usePagination = ({ total = -1, init = { page: DEFAULT_PAGINATION_PAGE, perPage: DEFAULT_PAGINATION_PER_PAGE } }: PaginationHookProps) => {
    const [pagination, setPagination] = useState<Pagination>(init)

    const firstPage = useMemo(() => () =>
        setPagination({
            ...pagination,
            page: 0
        }), [])

    const prevPage = () => {
        setPagination({
            ...pagination,
            page: pagination.page - 1
        })
    }

    const nextPage = () => {
        setPagination({
            ...pagination,
            page: pagination.page + 1
        })
    }

    const lastPage = () => {
        setPagination({
            ...pagination,
            page: Math.round(total / pagination.perPage) - 1
        })
    }

    const goToPage = (page: number) => {
        let p = page - 1

        if (page <= 0) {
            return
        }

        if (page > (Math.round(total / pagination.perPage))) {
            return
        }

        setPagination({
            ...pagination,
            page: p
        })
    }

    const onChangePagination = (action: PaginationAction, value?: any) => {
        switch (action) {
            case 'first':
                firstPage()
                break;
            case 'prev':
                prevPage()
                break;
            case 'next':
                nextPage()
                break;
            case 'last':
                lastPage()
                break;
            case 'goto':
                goToPage(value)
                break;
        }
    }

    return {
        pagination,
        firstPage,
        prevPage,
        lastPage,
        nextPage,
        goToPage,
        onChangePagination
    }
}

export default usePagination