import React, { ReactElement, FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { Link, useNavigate } from 'react-router-dom'

import api from '@services/auth.service'
import AuthFormWrapper from '@components/layout/AuthFormWrapper'

type FormData = {
  email: string
  password: string
  privacy: boolean
}

const LoginPage: FC = (): ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>()

  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {

      await api.login(data)

      navigate('/dashboard')

    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        reset({ password: '' })
        setErrorMessage(error.response?.data?.message || error.message)
      }
    }
  }

  return (
    <AuthFormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}
        className='border rounded-1 p-4'>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            {...register("email", { required: true })}
          />
          <div id="emailHelpBlock" className="form-text">
            {errors.email && <span>This field is required.</span>}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="inputPassword5" className="form-label">Password</label>
          <input
            type="password"
            id="inputPassword5"
            className="form-control"
            aria-labelledby="passwordHelpBlock"
            {...register("password", { required: true })}
          />
        </div>
        <div>
          <button
            type="submit"
            className='btn btn-primary'
            disabled={!isValid}>
            Accedi
          </button>
        </div>
        <div className='mt-3'>
          {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
        </div>
      </form>
      <div className='pt-3'>Se sei nuovo <Link to="/signup">registrati</Link></div>
      <div className='pt-3'><Link to="/reset-password">Password dimenticata?</Link></div>
    </AuthFormWrapper>
  );
};

export default LoginPage;
