export type TherapySessionType = {
    id: number
    name: string
}

export type TherapySession = {
    id: string
    clinicalEpisodeId: string
    date: Date
    description: string
    type: TherapySessionType
}

export type TherapySessionResponse = {
    id: string
    clinicalEpisodeId: string
    date: string
    description: string
    type: TherapySessionType
}

export type CreateTherapySession = {
    date: Date
    description: string
    type: TherapySessionType
}

export type UpdateTherapySession = Partial<CreateTherapySession> & {
    id: string
}

export const THERAPY_SESSION_TYPES: TherapySessionType[] = [
    {
        id: 1,
        name: 'Valutazione'
    },
    {
        id: 2,
        name: 'Trattamento'
    },
    {
        id: 3,
        name: 'Terapie fisiche'
    },
    {
        id: 4,
        name: 'Rieducazione neurologica'
    },
    {
        id: 5,
        name: 'Linfodrenaggio'
    },
    {
        id: 6,
        name: 'Rieducazione funzionale'
    },
    {
        id: 7,
        name: 'Trattamento pavimento pelvico'
    }
]