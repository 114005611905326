import React, { ReactElement, FC, useState, useEffect } from 'react'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import PatientForm from './PatientForm'
import { api } from '@services/patient/api'
import { Patient } from '@services/patient/model'

const PatientDetailPage: FC = (): ReactElement => {

    const [isUpdateMode, setIsUpdateMode] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    const [patient, setPatient] = useState<Patient>()

    useEffect(() => {
        (async function getPatient() {

            if (params.id) {
                setIsUpdateMode(true)

                const p = await api.findBy(params.id)

                setPatient(p)
            }

        })()
    }, [])

    const onSaveHandler = async (patient: Patient, cb: () => void) => {
        if (isUpdateMode) {
            try {
                await api.update(patient)
                cb()
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        } else {
            try {
                await api.create(patient)
                cb()
                navigate(`/patients`)
            } catch (error: unknown) {
                console.log({ error })
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        }
    }

    return <>
        <div className="container-fluid">
            <div className="row border-bottom py-3">
                <div className="col-12">
                    <PatientForm onSave={onSaveHandler} patient={patient} />
                </div>
            </div>
        </div>
    </>
};

export default PatientDetailPage