import React, { ReactElement, FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'

import { api as tenantApi } from '@/services/tenant/api'
import { currentUserState } from '@/state'
import { useAtom } from 'jotai/react'

type FormData = {
    name: string
}

const WorkspaceForm: FC<{ onCreate: Function }> = ({ onCreate }): ReactElement => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid },
    } = useForm<FormData>()
    const [currentUser] = useAtom(currentUserState)

    const [errorMessage, setErrorMessage] = useState('')

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            await tenantApi.create(data, { userId: currentUser?.user.id! })

            reset()

            onCreate()
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                setErrorMessage(error.response?.data?.message || error.message)
            } else if (error instanceof Error) {
                setErrorMessage(error.message)
            }
        }
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label
                    htmlFor="workspaceName"
                    className="form-label">Nome spazio di lavoro</label>
                <input
                    type="text"
                    className="form-control"
                    id="workspaceName"
                    {...register("name", { required: true })}
                />
            </div>
            <div>
                <button
                    type="submit"
                    className='btn btn-primary'
                    disabled={!isValid}>
                    Crea
                </button>
            </div>
            <div className='mt-3'>
                {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
            </div>
        </form>
    </>
};

export default WorkspaceForm