import { FC } from "react"

import "./Media.scss"

import { PublicWorkoutPlanExerciseMedia } from "@services/workout-plan/workout-plan-exercise/model"
import { VideoPlayer } from "@components/organisms/video-player/VideoPlayer"

const PLACEHOLDER_IMAGE = "https://placehold.co/800x1000"

type MediaProps = {
    media?: PublicWorkoutPlanExerciseMedia
    ar?: {
        width: number
        height: number
    }
}

export const Media: FC<MediaProps> = ({ media, ar = { width: 3, height: 4 } }) => {
    const style = { "--media-width": ar.width, "--media-height": ar.height } as React.CSSProperties;

    if (!media) {
        return <MediaPlaceholder {...{ style }} />
    }

    if (media.mime.includes('image')) {
        return <div className="media media--image" {...{ style }}>
            <div className="media__content">
                <img src={media.url.href} alt={media.title || ''} />
            </div>
        </div>
    }

    if (media.mime.includes('video')) {
        return <VideoPlayer {...{ media, ar }} />
    }

    return <div className="media media--not-supported" {...{ style }}>
        <div className="media__content">
            Not supported media
        </div>
    </div>
}

type MediaPlaceholderProps = {
    alt?: string
    style?: React.CSSProperties
}

const MediaPlaceholder: FC<MediaPlaceholderProps> = ({ alt, style }) => {
    return <div className="media media--placeholder" {...{ style }}>
        <img className="media__content"
            src={PLACEHOLDER_IMAGE}
            {...{ alt }} />
    </div>
}