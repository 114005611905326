import React, { ReactElement, FC, useState, useEffect } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import DatePicker from "react-datepicker"

import AddressForm from './AddressForm'
import { Patient } from '@services/patient/model'
import useCommonFormControlAttributes from '@hooks/useCommonFormControlAttributes'
import FormControlWrapper from '@components/atoms/FormControlWrapper'

const PatientForm: FC<{ onSave: (patient: Patient, cb: () => void) => void, patient?: Patient }> = ({ onSave, patient }): ReactElement => {
    const methods = useForm<Patient>()
    const { getFieldName, makeCommonFormControlAttributes, makeCommonFormControlWrapperAttributes } = useCommonFormControlAttributes()

    useEffect(() => {
        reset(patient)
    }, [patient])

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { isValid, isDirty }
    } = methods

    const [errorMessage] = useState('')

    const onSubmit: SubmitHandler<Patient> = async (data) => {
        const onSaveResetForm = () => reset(getValues())

        onSave(data, onSaveResetForm)
    }

    return <>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}
                className='border rounded-1 p-4 bg-white'>
                <div className="row mb-3">
                    <div className="col">
                        <FormControlWrapper
                            label='Nome*'
                            name={getFieldName('firstName')}
                            {...makeCommonFormControlWrapperAttributes('firstName')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('firstName')}
                                {...register('firstName', { required: true })}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col">
                        <FormControlWrapper
                            label='Cognome*'
                            name={getFieldName('lastName')}
                            {...makeCommonFormControlWrapperAttributes('lastName')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('lastName')}
                                {...register('lastName', { required: true })}
                            />
                        </FormControlWrapper>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <FormControlWrapper
                            label='Email'
                            name={getFieldName('email')}
                            {...makeCommonFormControlWrapperAttributes('email')}>
                            <input
                                type="email"
                                className="form-control"
                                {...makeCommonFormControlAttributes('email')}
                                {...register('email', {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Please enter a valid email!"
                                    }
                                })}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col">
                        <FormControlWrapper
                            label='Telefono'
                            name={getFieldName('phone')}
                            {...makeCommonFormControlWrapperAttributes('phone')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('phone')}
                                {...register('phone')}
                            />
                        </FormControlWrapper>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <FormControlWrapper
                            label='Data di nascita'
                            name={getFieldName('birthdate')}
                            {...makeCommonFormControlWrapperAttributes('birthdate')}>
                            <Controller
                                control={control}
                                name={getFieldName('birthdate') as any}
                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <DatePicker
                                        onChange={(event: Date) => onChange(event)} // send value to hook form
                                        onBlur={onBlur} // notify when input is touched/blur
                                        selected={value}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        withPortal
                                        showYearDropdown
                                        showMonthDropdown
                                        className='form-control'
                                        {...makeCommonFormControlAttributes('birthdate')}
                                    />
                                }
                            />
                        </FormControlWrapper>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <FormControlWrapper
                            label='Sports & Hobbies'
                            name={getFieldName('sports')}
                            {...makeCommonFormControlWrapperAttributes('sports')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('sports')}
                                {...register('sports')}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col">
                        <FormControlWrapper
                            label='Professione'
                            name={getFieldName('jobs')}
                            {...makeCommonFormControlWrapperAttributes('jobs')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('jobs')}
                                {...register('jobs')}
                            />
                        </FormControlWrapper>
                    </div>
                </div>

                <AddressForm type='addresses.residence' />
                <AddressForm title='Fatturazione' type='addresses.tax' />

                <div className="row mb-3">
                    <div className="col">
                        <FormControlWrapper
                            label='Codice fiscale'
                            name={getFieldName('TIN')}
                            {...makeCommonFormControlWrapperAttributes('TIN')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('TIN')}
                                {...register('TIN')}
                            />
                        </FormControlWrapper>
                    </div>
                    <div className="col">
                        <FormControlWrapper
                            label='P.IVA'
                            name={getFieldName('VAT')}
                            {...makeCommonFormControlWrapperAttributes('VAT')}>
                            <input
                                type="text"
                                className="form-control"
                                {...makeCommonFormControlAttributes('VAT')}
                                {...register('VAT')}
                            />
                        </FormControlWrapper>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        {patient ? 'Salva' : 'Crea'}
                    </button>
                </div>
                <div className='mt-3'>
                    {errorMessage.length > 0 && <span className="text-danger">{errorMessage}</span>}
                </div>
            </form>
        </FormProvider>
    </>
};

export default PatientForm