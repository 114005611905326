import { useState, useEffect } from "react"

import { City } from "@services/city/model"
import { Country } from "@services/country/model"
import { State } from "@services/state/model"
import { api } from "@services/city/api"

const useCities = ({ initValue, country, state }: { initValue?: City[], country: Country, state: State }) => {
    const [cities, setCities] = useState<City[]>(initValue || [])

    useEffect(() => {
        // LOAD CITIES OF SELECTED STATE
        if (country && state) {
            load(country, state)
        }

        async function load(country: Country, state: State) {
            const { data } = await api.find(
                { pagination: { perPage: 999999 }, sort: { name: 'ASC' } },
                { countryISO: country.iso, stateISO: state.iso }
            )

            setCities(data)
        }

    }, [country, state])

    return { cities }
}

export default useCities