import React, { ReactElement, FC } from 'react'
import { useAtom } from 'jotai/react'

import { api } from '@services/user/api'
import { UpdateMe } from '@services/user/model'
import { currentUserState } from '@/state'
import AccountForm from './AccountForm'
import useApi from '@/hooks/useApi'
import AsyncWrapper from '@/components/atoms/AsyncWrapper'

const AccountPage: FC = (): ReactElement => {
    const [currentUser, setCurrentUser] = useAtom(currentUserState)
    const { run: updateMe, ...updateStatus } = useApi({ callback: async (args) => await api.updateMe(args[0]) })
    const { run: fetchMe, ...fetchStatus } = useApi({ callback: async () => await api.me() })

    const onSaveHandler = async (u: UpdateMe, cb: () => void) => {
        const res = await updateMe(u)

        if (res) {
            const user = await fetchMe()
            setCurrentUser({ user, tenantId: currentUser?.tenantId || null })
            cb()
        }
    }

    return <>
        <div className="container-fluid">
            <div className="row py-3">
                <div className="col-12">
                    <AsyncWrapper {...updateStatus}>
                        <AsyncWrapper {...fetchStatus}>
                            {
                                currentUser
                                && <AccountForm
                                    user={currentUser.user}
                                    onSave={onSaveHandler} />
                            }
                        </AsyncWrapper>
                    </AsyncWrapper>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Utente loggato</h4>
                            {currentUser
                                && <>
                                    <ul className="list-group">
                                        <li className="list-group-item"><strong>ID:</strong> {currentUser.user.id}</li>
                                        <li className="list-group-item"><strong>Email:</strong> {currentUser.user.email}</li>
                                        <li className="list-group-item"><strong>Nome:</strong> {currentUser.user.firstName}</li>
                                        <li className="list-group-item"><strong>Cognome:</strong> {currentUser.user.lastName}</li>
                                        <li className="list-group-item"><strong>Stato:</strong> {currentUser.user.status === 'INACTIVE' ? 'Account da attivare' : 'Attivo'}</li>
                                        <li className="list-group-item"><strong>Lingua:</strong> {currentUser.user.language}</li>
                                        <li className="list-group-item"><strong>Piano:</strong> {currentUser.user.plan}</li>
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default AccountPage