import { APIClient } from '../api'
import { APIClientConfig } from '../api.model'
import { City, CityPathParams, CityResponse } from './model'

class CityAPI extends APIClient<unknown, City, any, unknown, CityPathParams, CityResponse> {

    protected fromResponseData(data: CityResponse): City {
        return {
            id: data.id,
            name: data.name,
            country: {
                id: data.countryId,
                code: data.countryCode
            },
            state: {
                id: data.stateId,
                code: data.stateCode
            }
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/countries/:countryISO/states/:stateISO/cities'
}

export const api = new CityAPI(CONFIG)

export default api