import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

import { WorkoutPlan } from '@services/workout-plan/model'
import { api } from '@services/workout-plan/api'
import useApi from '@hooks/useApi'
import AsyncWrapper from '@components/atoms/AsyncWrapper'

type WorkoutPlansWidgetProps = {
    patientId?: string
    clinicalEpisodeId?: string
}

const WorkoutPlansWidget: FC<WorkoutPlansWidgetProps> = ({ patientId, clinicalEpisodeId }) => {
    const navigate = useNavigate()
    const { run: fetchPlans, ...status } = useApi({
        callback: async () => {
            return await api.find({
                filters: {
                    ...(clinicalEpisodeId && { clinicalEpisodeId: { eq: clinicalEpisodeId } }),
                    ...(patientId && { patientId: { eq: patientId } })
                }
            })
        }
    })
    const [plans, setPlans] = useState<WorkoutPlan[]>([])

    useEffect(() => {
        (async function getLastPlans() {
            const plans = await fetchPlans()

            if (!plans) {
                return
            }

            const { data } = plans

            setPlans(data)
        })()
    }, [patientId, clinicalEpisodeId])

    const goToWorkoutPlanDashboardPage = (workoutPlan: WorkoutPlan) => navigate(`/patients/${workoutPlan.patientId}/workout-plan/${workoutPlan.id}`)

    return <>
        <AsyncWrapper {...status}>
            <ul className="list-group">
                {
                    plans
                        .map(p => <button
                            key={p.id}
                            type='button'
                            className="list-group-item list-group-item-action"
                            onClick={goToWorkoutPlanDashboardPage.bind(this, p)}>
                            {p.name} - [{p.validity.startDate.toLocaleString()} - {p.validity.endDate.toLocaleString()}]
                            <span className={`badge float-end ${p.validity.endDate < DateTime.now() ? 'bg-secondary' : 'bg-primary'}`}>
                                {
                                    p.validity.endDate < DateTime.now()
                                        ? 'Scaduta'
                                        : 'Attiva'
                                }
                            </span>
                        </button>)
                }
            </ul>
        </AsyncWrapper >
    </>
}

export default WorkoutPlansWidget