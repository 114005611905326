import { APIClient } from '../api'
import { APIClientConfig } from '../api.model'
import { CreateTenant, Tenant, TenantPathParams } from './model'

class TenantAPI extends APIClient<CreateTenant, Tenant, any, unknown, TenantPathParams, unknown> {

    protected fromResponseData(data: unknown): Tenant {
        throw new Error("Method not implemented.")
    }

    protected toPayload(resource: any): string | Object {
        return JSON.parse(JSON.stringify(resource))
    }

}

const CONFIG: APIClientConfig = {
    path: '/users/:userId/tenants'
}

export const api = new TenantAPI(CONFIG)