import React, { ReactElement, FC, ReactNode } from 'react'

import Logo from '@components/Logo'

type PropType = {
  children: ReactNode
}

const AuthFormWrapper: FC<PropType> = ({ children }): ReactElement => {
  return <>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10 col-md-4 mt-5">
          <div className='text-center p-5'>
            <Logo />
          </div>
          <div className='form-container'>
            {children}
          </div>
        </div>
      </div>
    </div>
  </>
};

export default AuthFormWrapper;
