import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { SingleValue } from 'react-select'

import './MediaForm.scss'

import { MEDIA_TYPE, MIME_TYPE } from '@services/media/model'
import FormControlWrapper from '@components/atoms/FormControlWrapper'
import useCommonFormControlAttributes from '@hooks/useCommonFormControlAttributes'

type MediaFormProps = {
    parentFormName: string | number
    onRemove?: (idx: any) => void
}

export type MediaFormData = {
    id?: string
    entityId?: string
    url: string
    language?: string
    mime: MIME_TYPE
    type: MEDIA_TYPE
    title?: string
    description?: string
}

const MediaForm: FC<MediaFormProps> = ({ parentFormName, onRemove }) => {
    const methods = useFormContext<Record<typeof parentFormName, MediaFormData>>()
    const { register } = methods
    const { getFieldName, makeCommonFormControlAttributes, makeCommonFormControlWrapperAttributes } = useCommonFormControlAttributes(parentFormName.toString())

    return <div className='media-form'>
        <div className="row g-3">
            <input type='hidden' {...register(getFieldName('id'))} />
            <input type='hidden' {...register(getFieldName('entityId'))} />

            <div className="col-12 col-md-2">
                <FormControlWrapper
                    label='Type'
                    name={getFieldName('type')}
                    {...makeCommonFormControlWrapperAttributes('type')}>
                    <Controller
                        name={getFieldName('type')}
                        render={({ field: { onChange, onBlur, value } }) =>
                            <Select
                                options={getMediaTypeOptions()}
                                value={getMediaTypeOption(value)}
                                onChange={onMediaTypeSelectChangeFactory(onChange)}
                                onBlur={onBlur}
                                {...makeCommonFormControlAttributes(`type`, { id: { attributeName: 'inputId' } })}
                            />
                        }
                    />
                </FormControlWrapper>
            </div>

            <div className="col-12 col-lg">
                <FormControlWrapper
                    label='URL'
                    name={getFieldName('url')}
                    {...makeCommonFormControlWrapperAttributes('url')}>
                    <input
                        type="text"
                        className="form-control"
                        {...makeCommonFormControlAttributes('url')}
                        {...register(getFieldName('url'), { required: true })}
                    />
                </FormControlWrapper>
            </div>

            <div className="col-12 col-md-2">
                <FormControlWrapper
                    label='MIME'
                    name={getFieldName('mime')}
                    {...makeCommonFormControlWrapperAttributes('mime')}>
                    <Controller
                        name={getFieldName('mime')}
                        render={({ field: { onChange, onBlur, value } }) =>
                            <Select
                                options={getMimeOptions()}
                                value={getMimeOption(value)}
                                onChange={onMimeSelectChangeFactory(onChange)}
                                onBlur={onBlur}
                                {...makeCommonFormControlAttributes(`mime`, { id: { attributeName: 'inputId' } })}
                            />
                        }
                    />
                </FormControlWrapper>
            </div>

            <div className="col-12 col-md-2">
                <FormControlWrapper
                    label='Title'
                    name={getFieldName('title')}
                    {...makeCommonFormControlWrapperAttributes('title')}>
                    <input
                        type="text"
                        className="form-control"
                        {...makeCommonFormControlAttributes('title')}
                        {...register(getFieldName('title'))}
                    />
                </FormControlWrapper>
            </div>

            <div className="col-12 col-md-2">
                <FormControlWrapper
                    label='Description'
                    name={getFieldName('description')}
                    {...makeCommonFormControlWrapperAttributes('description')}>
                    <input
                        type="text"
                        className="form-control"
                        {...makeCommonFormControlAttributes('description')}
                        {...register(getFieldName('description'))}
                    />
                </FormControlWrapper>
            </div>

            {
                !!onRemove
                && <div className="col-2 col-md-auto mt-auto">
                    <button className="btn btn-danger mb-1"
                        type='button'
                        onClick={onRemove}>
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            }
        </div>
    </div>
}

export { MediaForm }



const MimeMapper: Record<MIME_TYPE, string> = {
    'image/gif': 'gif',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/webp': 'webp',
    'video/mp4': 'mp4',
    'video/mpeg': 'mpeg',
    'video/ogg': 'ogg',
    'video/webm': 'webm',
}

function getMimeOptions() {
    return Object
        .keys(MimeMapper)
        .map(r => ({ value: r as MIME_TYPE, label: MimeMapper[r as MIME_TYPE] }))
}

function getMimeOption(status?: MIME_TYPE) {
    return status && { value: status, label: MimeMapper[status] }
}

function onMimeSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: MIME_TYPE, label: string }>) => {
        onChange(option?.value)
    }
}


const MediaTypeMapper: Record<MEDIA_TYPE, string> = {
    'featured': 'Featured',
    'gallery': 'Gallery',
}

function getMediaTypeOptions() {
    return Object
        .keys(MediaTypeMapper)
        .map(r => ({ value: r as MEDIA_TYPE, label: MediaTypeMapper[r as MEDIA_TYPE] }))
}

function getMediaTypeOption(status?: MEDIA_TYPE) {
    return status && { value: status, label: MediaTypeMapper[status] }
}

function onMediaTypeSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: MEDIA_TYPE, label: string }>) => {
        onChange(option?.value)
    }
}