import { APIClient } from '../api'
import { APIClientConfig } from '../api.model'
import { Country, CountryResponse } from './model'

class CountryAPI extends APIClient<unknown, Country, any, unknown, unknown, CountryResponse> {

    protected fromResponseData(data: CountryResponse): Country {
        return {
            id: data.id,
            name: data.name,
            iso: data.iso2,
            phoneCode: data.phoneCode,
            emoji: data.emoji
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/countries'
}

export const api = new CountryAPI(CONFIG)