import { FC, useMemo } from "react"
import {
    Calendar as BigCalendar,
    Views,
    luxonLocalizer,
    Messages,
    Event as CalendarEvent,
    SlotInfo,
} from 'react-big-calendar'
import { DateTime, Settings } from "luxon"

import './Calendar.scss'

import { Appointment } from "@services/calendar/appointment/model"

const DEFAULT_STEP = 30 //MINUTES
const MIN_HOUR = 7
const MAX_HOUR = 21
const DEFAULT_CULTURE = 'it-IT'
const DEFAULT_MESSAGES: Messages = {
    allDay: "Tutto il giorno",
    agenda: "Agenda",
    day: "Giorno",
    date: "Data",
    event: "Evento",
    month: "Mese",
    next: ">",
    noEventsInRange: "Nessun evento",
    previous: "<",
    showMore: (total) => `+${total} altro`,
    time: "Tempo",
    today: "Oggi",
    tomorrow: "Domani",
    week: "Settimana",
    work_week: "Settimana lavorativa",
    yesterday: "Ieri"
}

type CalendarProps = {
    events: Appointment[]
    onSelectSlot: (e: SelectedSlot) => void
    onSelectAppointment: (e: Appointment) => void
}

type SelectedSlot = { start: DateTime, end: DateTime }

export const Calendar: FC<CalendarProps> = ({ events, onSelectSlot, onSelectAppointment }) => {
    const { getNow, localizer, myEvents, scrollToTime, defaultDate, min, max, _onSelectSlot, onSelecting, _onSelectEvent } =
        useMemo(() => {
            const defaultTZ = DateTime.local().zoneName
            Settings.defaultZone = defaultTZ

            const transformedEvents: Array<CalendarEvent> = events.map(e => ({
                title: `${e.title}`,
                start: e.start.toJSDate(),
                end: e.end.toJSDate(),
                resource: { ...e }
            }))

            return {
                getNow: () => DateTime.local().toJSDate(),
                localizer: luxonLocalizer(DateTime),
                myEvents: transformedEvents,
                scrollToTime: DateTime.local().toJSDate(),
                defaultDate: DateTime.now().toJSDate(),
                min: new Date(1972, 0, 1, MIN_HOUR, 0, 0),
                max: new Date(1972, 0, 1, MAX_HOUR, 59, 59),
                onSelecting: () => false, // FIX ISSUE ON SELECTING RANGE
                _onSelectSlot: (slot: SlotInfo) => {
                    const start = DateTime.fromJSDate(slot.start)
                    const end = DateTime.fromJSDate(slot.end)
                    onSelectSlot({ start, end })
                },
                _onSelectEvent: (event: CalendarEvent) => {
                    onSelectAppointment(event.resource)
                }
            }
        }, [events])

    return <>
        <div className="calendar">
            <BigCalendar
                culture={DEFAULT_CULTURE}
                messages={DEFAULT_MESSAGES}
                localizer={localizer}
                defaultView={Views.WEEK}
                defaultDate={defaultDate}
                getNow={getNow}
                events={myEvents}
                scrollToTime={scrollToTime}
                min={min}
                max={max}
                step={DEFAULT_STEP}
                onSelectSlot={_onSelectSlot}
                onSelecting={onSelecting}
                onSelectEvent={_onSelectEvent}
                selectable
            />
        </div>
    </>
}