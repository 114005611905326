import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import Select, { MultiValue, SingleValue } from 'react-select'

import { ExerciseStatus } from "@services/exercise/exercise.model"
import useCommonFormControlAttributes from "@hooks/useCommonFormControlAttributes"
import FormControlWrapper from "@components/atoms/FormControlWrapper"
import { MediaListForm } from "@components/organisms/media/MediaListForm"
import { Term } from "@services/taxonomy/model"
import { Media } from "@services/media/model"

import diseaseAPI from "@services/exercise/disease.api"
import equipmentAPI from "@services/exercise/equipment.api"
import jointAPI from "@services/exercise/joint.api"
import movementAPI from "@services/exercise/movement.api"
import muscleAPI from "@services/exercise/muscle.api.ts"
import therapeuticGoalAPI from "@services/exercise/therapeutic-goal.api"

export type ExerciseFormData = {
    id?: string
    name: string
    description?: string
    slug?: string
    status: ExerciseStatus
    joints?: Term[]
    movements?: Term[]
    diseases?: Term[]
    muscles?: Term[]
    equipments?: Term[]
    therapeuticGoals?: Term[]
    mediaList?: Media[]
}

type ExerciseFormPros = {
    exercise?: ExerciseFormData
    onSave: (ex: ExerciseFormData, cb: (ex: ExerciseFormData) => void) => void,
}

const ExerciseForm: FC<ExerciseFormPros> = ({ onSave, exercise }): ReactElement => {
    const methods = useForm<ExerciseFormData>({ defaultValues: exercise })
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, isDirty }
    } = methods
    const {
        getFieldName,
        makeCommonFormControlAttributes,
        makeCommonFormControlWrapperAttributes
    } = useCommonFormControlAttributes()

    const [diseases, setDiseases] = useState<Term[]>([])
    const [equipments, setEquipments] = useState<Term[]>([])
    const [joints, setJoints] = useState<Term[]>([])
    const [movements, setMovements] = useState<Term[]>([])
    const [muscles, setMuscles] = useState<Term[]>([])
    const [therapeuticGoals, setTherapeuticGoals] = useState<Term[]>([])

    const isEditMode = !!exercise

    useEffect(() => {

        (async () => {
            const { data: _diseases } = await diseaseAPI.findTerms({ pagination: { perPage: -1 } })
            const { data: _equipments } = await equipmentAPI.findTerms({ pagination: { perPage: -1 } })
            const { data: _joints } = await jointAPI.findTerms({ pagination: { perPage: -1 } })
            const { data: _movements } = await movementAPI.findTerms({ pagination: { perPage: -1 } })
            const { data: _muscles } = await muscleAPI.findTerms({ pagination: { perPage: -1 } })
            const { data: _therapeuticGoals } = await therapeuticGoalAPI.findTerms({ pagination: { perPage: -1 } })

            setDiseases(_diseases)
            setEquipments(_equipments)
            setJoints(_joints)
            setMovements(_movements)
            setMuscles(_muscles)
            setTherapeuticGoals(_therapeuticGoals)
        })()

    }, [exercise])

    const onSubmit: SubmitHandler<ExerciseFormData> = async (data) => {
        const onSaveResetForm = (exercise: ExerciseFormData) => {
            reset(exercise)
        }

        onSave(data, onSaveResetForm)
    }

    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
                <div className="col">
                    <FormControlWrapper
                        label='Nome*'
                        name={getFieldName('name')}
                        {...makeCommonFormControlWrapperAttributes('name')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('name')}
                            {...register('name', { required: true })}
                        />
                    </FormControlWrapper>
                </div>
                <div className="col">
                    <FormControlWrapper
                        label='Slug'
                        name={getFieldName('slug')}
                        {...makeCommonFormControlWrapperAttributes('slug')}>
                        <input
                            type="text"
                            className="form-control"
                            {...makeCommonFormControlAttributes('slug')}
                            {...register('slug')}
                        />
                    </FormControlWrapper>
                </div>


                <div className="col">
                    <FormControlWrapper
                        label='Status'
                        name={getFieldName('status')}
                        {...makeCommonFormControlWrapperAttributes('status')}>
                        <Controller
                            name={getFieldName('status')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    options={getStatusOptions()}
                                    value={getStatusOption(value)}
                                    onChange={onStatusSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    {...makeCommonFormControlAttributes(`status`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Descrizione'
                        name={getFieldName('description')}
                        {...makeCommonFormControlWrapperAttributes('description')}>
                        <textarea
                            rows={10}
                            className="form-control"
                            {...makeCommonFormControlAttributes('description')}
                            {...register('description')}
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12 border p-3">
                    <MediaListForm fieldName="mediaList" />
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Diseases'
                        name={getFieldName('diseases')}
                        {...makeCommonFormControlWrapperAttributes('diseases')}>
                        <Controller
                            name={getFieldName('diseases')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(diseases)}
                                    defaultValue={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`diseases`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Equipments'
                        name={getFieldName('equipments')}
                        {...makeCommonFormControlWrapperAttributes('equipments')}>
                        <Controller
                            name={getFieldName('equipments')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(equipments)}
                                    value={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`equipments`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Joints'
                        name={getFieldName('joints')}
                        {...makeCommonFormControlWrapperAttributes('joints')}>
                        <Controller
                            name={getFieldName('joints')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(joints)}
                                    value={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`joints`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Movements'
                        name={getFieldName('movements')}
                        {...makeCommonFormControlWrapperAttributes('movements')}>
                        <Controller
                            name={getFieldName('movements')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(movements)}
                                    defaultValue={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`movements`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Muscles'
                        name={getFieldName('muscles')}
                        {...makeCommonFormControlWrapperAttributes('muscles')}>
                        <Controller
                            name={getFieldName('muscles')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(muscles)}
                                    defaultValue={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`muscles`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="col-12">
                    <FormControlWrapper
                        label='Therapeutic Goals'
                        name={getFieldName('therapeuticGoals')}
                        {...makeCommonFormControlWrapperAttributes('therapeuticGoals')}>
                        <Controller
                            name={getFieldName('therapeuticGoals')}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <Select
                                    isMulti
                                    options={transformTermsToOptions(therapeuticGoals)}
                                    defaultValue={transformTermsToOptions(value)}
                                    onChange={onMultiTermSelectChangeFactory(onChange)}
                                    onBlur={onBlur}
                                    isClearable={true}
                                    {...makeCommonFormControlAttributes(`therapeuticGoals`, { id: { attributeName: 'inputId' } })}
                                />
                            }
                        />
                    </FormControlWrapper>
                </div>

                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !isDirty}>
                        {isEditMode ? 'Salva' : 'Crea'}
                    </button>
                </div>
            </div>
        </form>

    </FormProvider>
}

export default ExerciseForm

function transformTermsToOptions(terms?: Term[]) {
    if (!terms) {
        return []
    }

    return terms.map(t => ({ value: t.id, label: t.name }))
}

function getStatusOptions() {
    return [
        { value: 'draft', label: 'Draft' },
        { value: 'publish', label: 'Published' }
    ] as { value: ExerciseStatus, label: string }[]
}

function getStatusOption(status?: ExerciseStatus) {
    return status && {
        value: status,
        label: status === 'draft' ? 'Draft' : 'Published'
    }
}

function onMultiTermSelectChangeFactory(onChange: (event: any) => void) {
    return (options: MultiValue<{ value: number, label: string }>) => {
        onChange(options.map(o => ({ id: o.value, name: o.label })))
    }
}

function onStatusSelectChangeFactory(onChange: (event: any) => void) {
    return (option: SingleValue<{ value: ExerciseStatus, label: string }>) => {
        onChange(option?.value)
    }
}