import React, { ReactElement, FC, useMemo } from 'react'
import { ColumnDef, Row, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { Tenant } from '@/services/tenant/model'
import { Pagination } from '@/services/api.model'
import { OnChangePaginationFn } from '@/hooks/usePagination'
import Table from '@/components/organisms/table/Table'
import TablePagination from '@/components/organisms/table/TablePagination'

type WorkspaceTableProps = {
    tenants: Tenant[]
    total: number
    currentTenantId: string | null | undefined,
    pagination: Pagination
    onChangePagination: OnChangePaginationFn
    onActivate: (tenant: Tenant) => void
    onDelete: (tenant: Tenant) => void
}


const WorkspaceTable: FC<WorkspaceTableProps> = ({ tenants, total, currentTenantId, pagination, onChangePagination, onActivate, onDelete }): ReactElement => {

    const columns = useMemo<ColumnDef<Tenant>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                cell: d => <span title={d.cell.getValue() as string}>{(d.cell.getValue() as string).substring(0, 4)}...</span>
            },
            {
                accessorKey: 'name',
                header: 'Nome'
            },
            {
                accessorKey: 'role',
                header: 'Ruolo',
            },
            {
                accessorKey: 'hostname',
                header: 'hostname',
            },
            {
                id: 'actions',
                header: 'Azioni',
                cell: props => <>
                    {
                        currentTenantId !== props.row.original.id &&
                        <>
                            <button className="btn btn-primary btn-sm" onClick={() => onActivate(props.row.original)}>Attiva</button>
                        </>
                    }
                    {
                        currentTenantId !== props.row.original.id 
                        && props.row.original.role === 'ADMIN' &&
                        <>
                            <button className="btn btn-danger btn-sm ms-1" onClick={() => onDelete(props.row.original)}>Elimina</button>
                        </>
                    }
                </>
            }
        ], [currentTenantId])

    const table = useReactTable<Tenant>({
        data: tenants,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        // Pagination
        manualPagination: true,
        pageCount: Math.ceil(total / pagination.perPage),
        state: {
            pagination: {
                pageIndex: pagination.page,
                pageSize: pagination.perPage
            }
        },
        autoResetPageIndex: true,
        meta: {
            getRowClass: (row: Row<Tenant>): string => currentTenantId === row.original.id ? 'table-primary' : '' //Highlight active row
        }
    })

    return <>
        <Table<Tenant> table={table} />
        <TablePagination<Tenant> table={table} onChangePagination={onChangePagination} />
    </>
};

export default WorkspaceTable