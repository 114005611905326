import { FC, useEffect, useState } from 'react'

import { api } from '@services/clinical-episode/therapy-session/api'
import { TherapySession } from '@services/clinical-episode/therapy-session/model'
import useApi from '@hooks/useApi'
import Modal from '@components/molecules/modal/Modal'
import AsyncWrapper from '@components/atoms/AsyncWrapper'
import { TypeClassMapper } from './constant'

const TherapySessionsWidget: FC<{ clinicalEpisodeId?: string | null }> = ({ clinicalEpisodeId }) => {
    const { run: fetchSessions, ...status } = useApi({ callback: async (id: string) => await api.find({}, { clinicalEpisodeId: id }) })
    const [sessions, setSessions] = useState<TherapySession[]>([])
    const [isSessionDetailOpen, setIsSessionDetailOpen] = useState(false)
    const [current, setCurrent] = useState<TherapySession | null>(null)

    useEffect(() => {
        (async function getLastClinicalEpisodeTherapySessions() {
            if (clinicalEpisodeId) {
                const { data } = await fetchSessions(clinicalEpisodeId)

                data && setSessions(data)
            }
        })()
    }, [clinicalEpisodeId])

    const showDetail = (ts: TherapySession) => {
        setCurrent(ts)
        setIsSessionDetailOpen(true)
    }

    return <>
        <AsyncWrapper {...status}>
            <ul className="list-group">
                {
                    sessions
                        .map(ts => <button
                            key={ts.id}
                            type='button'
                            className="list-group-item list-group-item-action"
                            onClick={() => showDetail(ts)}>
                            {ts.date.toLocaleDateString()}
                            <span className={`badge float-end ${TypeClassMapper[ts.type.id]}`}>
                                {ts.type.name}
                            </span>
                        </button>)
                }
            </ul>
        </AsyncWrapper >
        <Modal
            isOpen={isSessionDetailOpen}
            onRequestClose={() => { setIsSessionDetailOpen(false) }}>
            {
                current &&
                <div>
                    <div className='py-2'>
                        <span className={`badge ${TypeClassMapper[current.type.id]}`}>
                            {current.type.name}
                        </span>
                        <span className='float-end'>
                            {current.date.toLocaleDateString()}
                        </span>
                    </div>
                    <div
                        className='pt-2'
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{ __html: current.description }} />
                </div>
            }
        </Modal>
    </>
}

export default TherapySessionsWidget