import { FC, ReactElement, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { api } from '@services/exercise/exercise.api'
import { Exercise } from "@services/exercise/exercise.model"
import usePagination from "@hooks/usePagination"
import ExerciseTable from "./ExerciseTable"

export const ExerciseListPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const [exerciseList, setExerciseList] = useState<Exercise[]>([])
    const [total, setTotal] = useState(-1)
    const { pagination, onChangePagination } = usePagination({ total })

    useEffect(() => {
        (async () => {
            await fetchExercises()
        })()
    }, [pagination])

    const fetchExercises = async () => {
        const { data, meta } = await api.find({ pagination })

        setExerciseList(data)
        setTotal(meta.pagination.total)
    }

    const deleteExerciseHandler = async (e: Exercise) => {
        const res = await api.remove(e.id)

        if (res) {
            await fetchExercises()
        }
    }

    return <div className="container-fluid">
        <div className="row py-3 g-3">
            <div className="col-12">
                <button className='btn btn-primary mb-3' onClick={() => navigate('/exercises/new')}>Crea</button>
                <div className="card">
                    <div className="card-body">
                        <ExerciseTable
                            exercises={exerciseList}
                            onDelete={deleteExerciseHandler}
                            pagination={pagination}
                            onChangePagination={onChangePagination}
                            total={total}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ExerciseListPage