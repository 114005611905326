import React, { ReactElement, FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '@services/patient/api'
import { Patient } from '@services/patient/model'
import usePagination from '@/hooks/usePagination'
import PatientTable from './PatientTable'

const PatientsPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const [patients, setPatients] = useState<Patient[]>([])
    const [total, setTotal] = useState(-1)
    const { pagination, onChangePagination } = usePagination({ total })

    useEffect(() => {
        (async function getPatients() {
            const { data: p, meta } = await api.find({ pagination: { ...pagination } })

            setPatients(p)
            setTotal(meta.pagination.total)
        })()
    }, [pagination])

    const onDelete = async (patient: Patient) => {
        // DELETE PATIENT
        await api.remove(patient.id)

        // RELOAD PATIENTS
        const { data: p, meta } = await api.find({ pagination: { ...pagination } })

        setPatients(p)
        setTotal(meta.pagination.total)
    }

    return <>
        <div className="container-fluid">
            <div className="row py-3">
                <div className="col-12">
                    <button className='btn btn-primary mb-3' onClick={() => navigate('/patients/new')}>Crea</button>
                    <PatientTable
                        patients={patients}
                        onDelete={onDelete}
                        pagination={pagination}
                        onChangePagination={onChangePagination}
                        total={total}
                    />
                </div>
            </div>
        </div>
    </>
};

export default PatientsPage