import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import './MediaListForm.scss'

import { MediaForm, MediaFormData } from './MediaForm'

type MediaFormProps = {
    fieldName: string
}

type InputFormData = MediaFormData[]

const MediaListForm: FC<MediaFormProps> = ({ fieldName }) => {
    const methods = useFormContext<Record<string, InputFormData>>()
    const { control } = methods
    const { fields, append, remove } = useFieldArray<Record<string, InputFormData>>({ name: fieldName, control })

    const removeHandler = (idx: number) => {
        remove(idx)
    }

    const addMediaHandler = () => {
        append({
            mime: 'image/jpeg',
            type: 'featured',
            url: '',
            title: '',
            description: '',
        })
    }

    return <div className='media-form-list'>
        {
            fields.map((field, idx) => {
                return <MediaForm key={idx}
                    parentFormName={`${fieldName}.${idx}`}
                    onRemove={removeHandler.bind(this, idx)} />
            })
        }
        <div className={`col-12`}>
            <button
                type="button"
                className="btn btn-primary"
                onClick={addMediaHandler}>
                Aggiungi media
            </button>
        </div>
    </div>
}

export { MediaListForm }