import React, { ReactElement, FC } from 'react'
import { AxiosError } from 'axios'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { api } from '@services/exercise/exercise.api'
import { Exercise, CreateExercise, UpdateExercise } from '@services/exercise/exercise.model'
import { api as mediaApi } from '@services/media/api'
import { CreateMedia, Media } from '@services/media/model'
import ExerciseForm, { ExerciseFormData } from './ExerciseForm'

type ExerciseDetailPageProps = {
    isEdit?: boolean
}

const ExerciseDetailPage: FC<ExerciseDetailPageProps> = ({ isEdit }): ReactElement => {
    const loaderData = useLoaderData() as { exercise?: Exercise, mediaList: Media[] } | undefined
    const navigate = useNavigate()
    const exercise = loaderData && loaderData.exercise
    const mediaList = (loaderData && loaderData.mediaList) || []
    const exerciseFormData: ExerciseFormData | undefined = exercise && { ...exercise, mediaList: [...mediaList] }

    const onSaveHandler = async (exForm: ExerciseFormData, cb: (exercise: ExerciseFormData) => void) => {
        if (isEdit) {
            try {

                const uEx: UpdateExercise = {
                    ...exForm,
                    id: exercise!.id,
                }

                // CRUD MEDIA
                const mediaListFormId = exForm.mediaList?.map(m => m.id) || []
                const mediaToDelete = mediaList.map(m => m.id).filter(x => !mediaListFormId.includes(x))
                const mediaToUpdate = exForm.mediaList?.filter(m => m.id.length > 0)
                const mediaToCreate = exForm.mediaList?.filter(m => m.id.length === 0)

                if (mediaToDelete) {
                    for (const id of mediaToDelete) {
                        await mediaApi.remove(id)
                    }
                }

                if (mediaToUpdate) {
                    for (const m of mediaToUpdate) {
                        await mediaApi.update({
                            ...m,
                            entityId: exercise!.id
                        })
                    }
                }

                if (mediaToCreate) {
                    for (const m of mediaToCreate) {
                        await mediaApi.create({
                            ...m,
                            entityId: exercise!.id
                        })
                    }
                }

                const updatedExercise = await api.update(uEx) as Exercise
                const updatedMediaList = await mediaApi.findByEntityId(exercise!.id)

                cb({ ...updatedExercise, mediaList: updatedMediaList })
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        } else {
            try {

                const cEx: CreateExercise = { ...exForm }

                const exId = await api.create(cEx)

                //CREATE MEDIA
                if (exForm.mediaList) {
                    for (const m of exForm.mediaList) {
                        const newMedia = {
                            ...m,
                            entityId: exId,
                            language: 'it',
                        } as CreateMedia

                        await mediaApi.create(newMedia)
                    }
                }

                navigate(`/exercises/${exId}`)
            } catch (error: unknown) {
                console.log({ error })
                if (error instanceof AxiosError) {
                    alert(error.message)
                }
            }
        }
    }

    return <>
        <div className="container-fluid">
            <div className="row border-bottom py-3">
                <div className="col-12">
                    <ExerciseForm
                        onSave={onSaveHandler}
                        exercise={exerciseFormData} />
                </div>
            </div>
        </div>
    </>
};

export default ExerciseDetailPage