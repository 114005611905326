
import { APIClient } from '../api'
import { APIClientConfig } from '../api.model'
import { State, StatePathParams, StateResponse } from './model'

class StateAPI extends APIClient<unknown, State, any, unknown, StatePathParams, StateResponse> {

    protected fromResponseData(data: StateResponse): State {
        return {
            id: data.id,
            name: data.name,
            iso: data.iso2,
            type: data.type,
            country: {
                id: data.countryId,
                code: data.countryCode
            }
        }
    }

}

const CONFIG: APIClientConfig = {
    path: '/countries/:countryISO/states'
}

export const api = new StateAPI(CONFIG)