import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"
import { jwtDecode } from "jwt-decode"

import { getCurrentUserState, getJwtState, setCurrentUserState, setJwtState } from "@/state"
import { API_HOST, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_PER_PAGE } from "./constants"
import { APIClientConfig, FindParams, PathParams, ResponseMeta } from "./api.model"

const BASE_AXIOS_CONFIG: CreateAxiosDefaults = {
    baseURL: API_HOST,
    withCredentials: true,
    paramsSerializer: {
        indexes: null //rimuove indici da array in query string
    }
}

const JWT_AUTO_REFRESH_ROUTE_EXCLUSION = ['auth/login']

export const client: AxiosInstance = axios.create(BASE_AXIOS_CONFIG)

// ADD AUTHORIZATION AND X-TENANT-ID HEADERS
client.interceptors.request.use(
    function updateConfig(config) {

        const jwt = getJwtState()
        const currentUser = getCurrentUserState()

        if (jwt) {
            config.headers['authorization'] = `Bearer ${jwt.token}`

            if (currentUser?.tenantId) {
                config.headers['x-tenant-id'] = currentUser.tenantId
            }
        }

        return config
    },
    function onError(error) {
        return Promise.reject(error);
    }
)

// HANDLE REFRESH TOKEN REQUEST
client.interceptors.response.use(
    function onResponse(res) {
        // DO NOTHING
        return res
    },
    async function onError(err) {
        const originalConfig = err.config;

        if (!JWT_AUTO_REFRESH_ROUTE_EXCLUSION.includes(originalConfig.url) && err.response) {

            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {

                    // Call api for refresh token 
                    // but use default axios client in order to avoid request loop
                    const res = await axios({
                        baseURL: BASE_AXIOS_CONFIG.baseURL,
                        withCredentials: BASE_AXIOS_CONFIG.withCredentials,
                        method: 'POST',
                        url: "/auth/refresh"
                    })

                    const { token: jwt } = res.data
                    const decodedJwt = jwtDecode(jwt)

                    setJwtState({
                        token: jwt,
                        decoded: decodedJwt
                    })

                    return client(originalConfig);
                } catch (_error) {
                    // Erase global state data
                    setJwtState(null)
                    setCurrentUserState(null)
                    //TODO refactoring this redirection
                    // eslint-disable-next-line no-restricted-globals
                    location.replace('/login')

                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
)

/**  
 * // TODO: interceptor che imposta il loader quando ci sono delle chiamate in corso.
 *          Problematica: Se ho chiamate sotto al culo di aggiornamento di alcuni componenti
 *          rischio di avere l'interfaccia che diventa utilizzabile a singhiozzo...bad UX. 
 *          Forse conviene gestirla con un loader meno invasivo (barra di avanzamento sottile nell'header/footer)
 *          oppure con un loader nel componente specifico.
*/

/**  
 * // TODO: interceptor che mostra notifica toast quando la chiamata va in errore.
 *          Problematica: se l'errore riguarda uno o più campi del form forse conviene mostrarlo/i 
 *          direttamente nell'interfaccia piuttosto che in una notifica toast.
*/

export abstract class APIClient<C, R, U extends { id: any }, D, P, RESPONSE> {
    private config: APIClientConfig
    constructor(config: APIClientConfig) {
        this.config = config
    }

    protected get path() {
        return this.config.path
    }

    protected resolvePath(pathParams?: PathParams<P>): string {
        const {
            path
        } = this.config

        if (!pathParams) {
            return path
        }

        const segments = path.slice(1).split('/')
        let resolved = ''
        for (const segment of segments) {
            if (':' === segment.charAt(0)) {
                const name = segment.slice(1) as keyof P

                if (pathParams[name] === undefined) {
                    throw new Error(`${segment} param is missing!`)
                }

                resolved += `/${pathParams[name]}`
            } else {
                resolved += `/${segment}`
            }
        }

        return resolved
    }

    public async find(params: FindParams<R>, pathParams?: PathParams<P>): Promise<{ data: R[], meta: ResponseMeta }> {
        const mergedParams: FindParams<R> = {
            pagination: {
                page: DEFAULT_PAGINATION_PAGE,
                perPage: DEFAULT_PAGINATION_PER_PAGE,
                withCount: true,
                ...params.pagination
            },
            ...(params.sort && { sort: params.sort }),
            ...(params.filters && { filters: params.filters })
        }
        const { data } = await client.get<{ data: RESPONSE[], meta: ResponseMeta }>(this.resolvePath(pathParams), { params: mergedParams })

        return { data: this.fromResponseArrayData(data.data) as R[], meta: data.meta }
    }

    public async findBy(id: string | number, pathParams?: PathParams<P>): Promise<R | undefined> {
        const { data } = await client.get<RESPONSE>(`${this.resolvePath(pathParams)}/${id}`)

        return this.fromResponseData(data) as R
    }

    public async create(resource: C, pathParams?: PathParams<P>): Promise<string> {
        const { status, headers } = await client.post<boolean>(`${this.resolvePath(pathParams)}`, this.toPayload(resource))

        if (status !== 201) {
            throw new Error('Error on create resource')
        }

        const newLocation: string[] = headers['location'].split('/')
        const newId = newLocation[newLocation.length - 1]

        return newId
    }

    public async update(resource: U, pathParams?: PathParams<P>): Promise<R | undefined> {
        const { data } = await client.patch<RESPONSE>(`${this.resolvePath(pathParams)}/${resource.id}`, this.toPayload(resource))

        return this.fromResponseData(data) as R
    }

    public async remove(id: string | number, pathParams?: PathParams<P>): Promise<boolean> {
        const { status } = await client.delete(`${this.resolvePath(pathParams)}/${id}`)

        return status === 204
    }

    protected toPayload(resource: C | R | U): Object | string {
        throw new Error("Method not implemented.")
    }

    protected fromResponseData(data: RESPONSE): R {
        throw new Error("Method not implemented.")
    }

    protected fromResponseArrayData(data: RESPONSE[]): R[] {
        if (data instanceof Array) {
            return data.map(d => this.fromResponseData(d))
        }

        return []
    }
}