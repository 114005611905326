import { FC, ReactElement, useEffect, useState } from "react"
import { TAXONOMY_ID, TAXONOMY_LIST } from "@services/exercise/taxonomies.model"
import diseaseAPI from "@services/exercise/disease.api"
import equipmentAPI from "@services/exercise/equipment.api"
import jointAPI from "@services/exercise/joint.api"
import movementAPI from "@services/exercise/movement.api"
import muscleAPI from "@services/exercise/muscle.api.ts"
import therapeuticGoalAPI from "@services/exercise/therapeutic-goal.api"
import { Term, CreateTerm, UpdateTerm } from "@services/taxonomy/model"
import { TaxonomyAPIFactoryType } from "@services/taxonomy/api"
import usePagination from "@hooks/usePagination"
import TaxonomyForm from "./TaxonomyForm"
import TaxonomyTable from "./TaxonomyTable"

type TaxonomyPageProps = {
    id: TAXONOMY_ID
}

const apiMapper: Record<TAXONOMY_ID, TaxonomyAPIFactoryType> = {
    "disease": diseaseAPI,
    "equipment": equipmentAPI,
    "joint": jointAPI,
    "movement": movementAPI,
    "muscle": muscleAPI,
    "therapeutic-goal": therapeuticGoalAPI
}

export const TaxonomyPage: FC<TaxonomyPageProps> = ({ id }): ReactElement => {
    const api: TaxonomyAPIFactoryType = apiMapper[id]
    const [termList, setTermList] = useState<Term[]>([])
    const [term, setTerm] = useState<Term>()
    const [total, setTotal] = useState(-1)
    const { pagination, onChangePagination } = usePagination({ total })
    const isUpdate = !!term
    const taxonomy = TAXONOMY_LIST.find(t => t.id === id) as { id: TAXONOMY_ID, name: string }

    useEffect(() => {
        (async () => {
            await fetchTerms()
        })()
    }, [id, pagination])

    const fetchTerms = async () => {
        const { data, meta } = await api.findTerms({ pagination, sort: { id: 'DESC' } })

        setTermList(data)
        setTotal(meta.pagination.total)
    }

    const onSaveHandler = async (t: { name: string }) => {
        if (isUpdate) {
            const updateTerm = {
                id: term.id,
                name: t.name
            } as UpdateTerm

            await api.updateTerm(updateTerm)
        } else {
            const createTerm = {
                name: t.name
            } as CreateTerm

            await api.createTerm(createTerm)
        }

        await fetchTerms()
        setTerm(undefined)
    }

    const deleteTerm = async (id: number) => {
        const res = await api.removeTerm(id)

        if (res) {
            setTerm(undefined)
            await fetchTerms()
        }
    }

    const setUpdateForm = (t: Term) => {
        setTerm(t)
    }

    return <div className="container-fluid">
        <div className="row py-3 g-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h4>{isUpdate ? 'Modifica' : 'Crea nuova'} {taxonomy.name}</h4>
                        <TaxonomyForm
                            onSave={onSaveHandler}
                            term={term}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <TaxonomyTable
                            terms={termList}
                            onDelete={(t) => deleteTerm(t.id)}
                            onUpdate={setUpdateForm}
                            pagination={pagination}
                            onChangePagination={onChangePagination}
                            total={total}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default TaxonomyPage