import { APIClient } from '../api'
import { Media, CreateMedia, UpdateMedia, MediaResponse } from './model'

import { APIClientConfig, FindParams } from '../api.model'

class MediaAPI extends APIClient<CreateMedia, Media, UpdateMedia, unknown, unknown, MediaResponse> {

    protected fromResponseData(data: MediaResponse): Media {
        return {
            id: data.id,
            entityId: data.entityId,
            url: data.url,
            language: data.language,
            mime: data.mime,
            type: data.type,
            ...(data.title && { title: data.title }),
            ...(data.description && { description: data.description }),
        }
    }

    protected toPayload(r: Media | CreateMedia | UpdateMedia): string | Object {
        return {
            ...('id' in r && { id: r.id }),
            ...('entityId' in r && { entityId: r.entityId }),
            ...('url' in r && { url: r.url }),
            ...('language' in r && { language: r.language }),
            ...('mime' in r && { mime: r.mime }),
            ...('type' in r && { type: r.type }),
            ...('title' in r && { title: r.title }),
            ...('description' in r && { description: r.description }),
        }
    }

    public async findByEntityId(entityId: string): Promise<Media[]> {
        const filters: FindParams<Media> = {
            filters: {
                entityId: {
                    eq: entityId
                }
            },
            pagination: {
                perPage: -1
            }
        }

        const { data } = await this.find(filters)

        return data
    }
}

const CONFIG: APIClientConfig = {
    path: 'media'
}

export const api = new MediaAPI(CONFIG)